const isTokenInLocalStorage = () => {
  let isAccessTokenInLocalStorage = false;

  if (localStorage.getItem("accessToken")) {
    isAccessTokenInLocalStorage = true;
  }

  return isAccessTokenInLocalStorage;
};

export default isTokenInLocalStorage;
