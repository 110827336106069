import { Typography } from "@mui/material";
import {
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { CustomSort } from "../../../components/DataGrid";
export const EventTypesColumns = (actionsColumns: any) => {
  return [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Protocol Steps",
      field: "protocolSteps",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    // createdAt
    // school name
    {
      headerName: "Drill Emergency",
      field: "drillEmergency",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "School Name",
      field: "school",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      valueGetter: (params: any) => params.row.school.name,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    actionsColumns,
  ];
};
