import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import {
  CometChatConversationList,
  CometChatGroupList,
  CometChatMessages,
  CometChatUserList,
} from "../CometChatWorkspace/src";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import { styles } from "./styles";
import { IGUIState } from "./interfaces";

const initialState: IGUIState = {
  type: "User",

  value: "",
};

/**
 * Its a custom component of CometChat
 * @returns {React.ReactElement}
 */
function CustomCometChat() {
  const [GUI, setGUI] = useState<IGUIState>(initialState);
  const [value, setValue] = React.useState<0 | 1 | 2>(0); //

  // --- FUNCTIONS

  function handleClickGUI(value: any) {
    const isGroup = value.membersCount;
    if (isGroup) {
      setGUI({ type: "Group", value: value.guid });
    } else {
      setGUI({ type: "User", value: value.uid });
    }
  }

  function handleClose() {
    setGUI(initialState);
  }

  // CONDITIONAL COMPONENTS
  const componentChat = {
    0: <CometChatConversationList onItemClick={handleClickGUI} />,
    1: <CometChatUserList onItemClick={handleClickGUI} friendsOnly={true} />,
    2: <CometChatGroupList onItemClick={handleClickGUI} />,
  };

  const componentListMessage = (value: IGUIState) =>
    value.type === "Group" ? (
      <CometChatMessages
        customeClose
        close={handleClose}
        chatWithGroup={GUI.value}
      />
    ) : (
      <CometChatMessages
        customeClose
        close={handleClose}
        chatWithUser={GUI.value}
      />
    );

  return (
    <Paper sx={styles.card}>
      <Box sx={{ height: "100%" }}>
        {GUI.value ? componentListMessage(GUI) : componentChat[value]}
      </Box>
      {!GUI.value && (
        <Box sx={{ flexShrink: 0 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
          >
            <BottomNavigationAction label="Chats" icon={<ChatBubbleIcon />} />
            <BottomNavigationAction label="Users" icon={<PersonIcon />} />
            <BottomNavigationAction label="Groups" icon={<GroupIcon />} />
          </BottomNavigation>
        </Box>
      )}
    </Paper>
  );
}

export default CustomCometChat;
