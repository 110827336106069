import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useStyles } from "./styles";

type LabelFieldProps = {
  label: string;
  value?: string | Date;
};

export const LabelField: React.FC<LabelFieldProps> = ({ label, value }) => {
  const styles = useStyles();
  return (
    <Grid
      container
      spacing={1}
      flexDirection={"column"}
      justifyContent={"space-between"}
      marginY={".3rem"}
    >
      <Grid item xs={5}>
        <Typography variant="body1" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={styles.textContainer} variant="body1">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
