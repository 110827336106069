import { CometChat } from "@cometchat-pro/chat";
import code from "material-ui/svg-icons/action/code";
import { v4 as uuidv4 } from "uuid";

import axios from "../../config/axios";
import { IDistrict } from "../../models/District";
import { ERoles, ROLES } from "../../pages/routes/role-catalog";
import { COMET_CHAT_CONST } from "../../services/commet-chat";
import getLocalAccessToken from "../../utils/helpers/get-local-access-token";
import { ACTION_TYPES } from "../reducers/LoggedUser";
import { schoolDisabled, userDisabled } from "./disabled";

export const changeValueError = (value: string) => (dispatch: any) => {
  dispatch({ type: ACTION_TYPES.CHANGE_VALUE_ERROR, payload: value });
};

// ----- LOGIN -----
export const login = () => ({ type: ACTION_TYPES.LOGIN });
export const loginFailed = (error: any) => ({
  type: ACTION_TYPES.LOGIN_FAILED,
  data: error,
});
export const loginSuccess = (response: any) => ({
  type: ACTION_TYPES.LOGIN_SUCCESS,
  data: response,
});

export const loginUser = (session: any, remindMeSession: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(login());
      const response = await axios.post("/login", { ...session });
      const code = response.data.payload.user.role.code;
      const userData = response.data.payload;
      const isUserDisabled = response?.data?.payload?.user?.disabled;

      if (
        code === ERoles.RL002 ||
        code === ERoles.RL003 ||
        code === ERoles.RL004 ||
        code === ERoles.RL005
      ) {
        await CometChat.login(userData.user._id, COMET_CHAT_CONST.authKey);
      }
      if (
        response.data.payload.user.role.name !== ROLES.RL000.name &&
        response.data.payload.user.role.name !== ROLES.RL001.name
      ) {
        const isSchoolDisabled = response.data.payload.user.school.disabled;
        if (isSchoolDisabled) {
          dispatch(loginFailed("School is disabled , contact your admin"));
          dispatch(schoolDisabled());
        } else {
          loginSuccessActions(response.data.payload, remindMeSession)(dispatch);
        }
      } else if (isUserDisabled) {
        dispatch(loginFailed("User is disabled , contact"));
        dispatch(userDisabled());
      } else {
        loginSuccessActions(response.data.payload, remindMeSession)(dispatch);
      }
    } catch (error) {
      const err = error as any;
      dispatch(loginFailed(err.data?.message));
    }
  };
};

// ----- USER SELF -----
export const checkUserSelf = () => {
  return async (dispatch: any) => {
    dispatch(login());
    try {
      if (!getLocalAccessToken()) {
        disableAppLoader()(dispatch);
        return;
      }
      const response = await axios.get("/users/user-self");
      const userData = response.data.payload;
      const code = userData.user.role.code;
      if (
        code === ERoles.RL002 ||
        code === ERoles.RL003 ||
        code === ERoles.RL004
      ) {
        await CometChat.login(userData.user._id, COMET_CHAT_CONST.authKey);
      }
      dispatch(loginSuccess(userData));
    } catch (error) {
      const err = error as any;
      if (err.data?.message) {
        dispatch(loginFailed(err.data?.message));
      } else {
        disableAppLoader()(dispatch);
      }
    }
  };
};

// ----- DISABLE APP LOADER  -----
export const disableLoader = () => ({
  type: ACTION_TYPES.DISABLE_APP_LOADER,
});

export const disableAppLoader = () => {
  return async (dispatch: any) => {
    dispatch(disableLoader());
  };
};

export const loginSuccessActions = (
  responseData: any,
  remindMeSession = true,
) => {
  return async (dispatch: any) => {
    const { user, refreshToken } = responseData;
    const { accessToken } = user;

    // Save JWT Token
    if (remindMeSession) {
      setLocalStorageUser(accessToken, refreshToken);
    } else {
      setSessionStorageUser(accessToken, refreshToken);
    }

    // Save loggedUser into Redux
    if (
      user.role === ERoles.RL002 ||
      user.role === ERoles.RL003 ||
      user.role === ERoles.RL004
    ) {
      await CometChat.login(user._id, COMET_CHAT_CONST.authKey);
    }
    return dispatch(loginSuccess({ user }));
  };
};

// ----- LOGOUT -----
export const logout = () => ({
  type: ACTION_TYPES.LOGOUT,
});

export const logoutUser = () => {
  return async (dispatch: any) => {
    try {
      await axios.post("/logout");
    } catch (error) {
      //If the logout request fails, continue with the logout process
    }

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    dispatch(logout());
  };
};

export const setLocalStorageUser = (
  accessToken: string,
  refreshToken: string,
) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

export const setSessionStorageUser = (
  accessToken: string,
  refreshToken: string,
) => {
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);
};

export const CHANGE_VALUE_EVENT_PROGRESS = "CHANGE_VALUE_EVENT_PROGRESS";
export const changeValueEventProgress = (value?: string) => (dispatch: any) => {
  dispatch({
    type: CHANGE_VALUE_EVENT_PROGRESS,
    payload: value,
  });
};

export const ADD_DISTRICT_TO_USER = "ADD_DISTRICT_TO_USER";
export const addDistrictToUser = (data: IDistrict[]) => {
  return {
    type: ADD_DISTRICT_TO_USER,
    payload: data,
  };
};
