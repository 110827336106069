import { GridColDef } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu } from "../../components/DataGrid";
import { displayToast } from "../../utils/helpers/displayToast";
import { ISelectOption } from "../../components/Inputs/Select";
import { useHistory } from "react-router-dom";
import { EventTypesColumns } from "./config/column";
import { getAllEvents } from "../../services/eventInProgress";
import { EVENT_EMERGENCY_TYPES } from "../../constants";

const EventTypes: React.FC = () => {
  const history = useHistory();

  const [types, setTypes] = useState<any>();
  const [events, setEvents] = useState([]);
  const [emergencyType, setEmergencyType] = useState<any>();
  const [search, setSearch] = useState<string>("");
  const allTypes = { value: "All Types", name: "All Types" };
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchevents = async (): Promise<void> => {
    try {
      const response = await getAllEvents();
      if (response.data.success) {
        const eventsToSet = response.data.payload.map((event: any) => {
          return {
            id: event._id,
            name: event.name,
            ...event,
          };
        });
        setEvents(eventsToSet);
      }
      /*  */
    } catch (error) {
      displayToast("error", "An error has happened fetching the events");
    } finally {
      setIsLoading(false);
    }
  };

  function setLocationTypes() {
    const emergencyTypes: ISelectOption[] = EVENT_EMERGENCY_TYPES.map(
      (alertType: any) => {
        return {
          value: alertType.charAt(0).toUpperCase() + alertType.slice(1),
          name: alertType.charAt(0).toUpperCase() + alertType.slice(1),
        };
      },
    );
    setTypes([allTypes, ...emergencyTypes]);
  }

  useEffect(() => {
    setLocationTypes();
    setEmergencyType("All Types");
  }, []);

  useEffect(() => {
    fetchevents();
  }, []);
  function renderModalMenu(id: string, isActivate: boolean) {
    const detailsOptionsList = [
      {
        name: "Update Event Type",
        action: () => history.push(`/update-event-type/${id}`),
      },
    ];

    return isActivate ? <ModalMenu items={detailsOptionsList} /> : null;
  }

  const actionsColumns: GridColDef = {
    headerName: "ACTIONS",
    field: "",
    hideSortIcons: true,
    renderCell: (data) => {
      return renderModalMenu(data.row._id, !!data.row.school?._id);
    },
  };

  const handleFilter = () =>
    events
      ?.filter(
        (report: any) =>
          search === "" ||
          report.name.toLowerCase().includes(search.toLowerCase()) ||
          !search,
      )
      .filter(
        (report: any) =>
          report?.drillEmergency
            ?.toLowerCase()
            .includes(emergencyType.toLowerCase()) ||
          emergencyType === "All Types",
      );
  return (
    <>
      <PageTitle title="Event Types" />
      <div
        style={{ marginTop: "115px", marginLeft: "32px", marginRight: "32px" }}
      >
        <DataGrid
          disableSelectionOnClick
          columns={EventTypesColumns(actionsColumns)}
          rows={handleFilter()}
          loading={isLoading}
          toolbarProps={{
            buttonText: "Add Event Type",
            buttonAction: () => {
              history.push("/add-event-type");
            },
            filterOptions: types,
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              setEmergencyType(event.target.value as string);
            },
            hideIcons: true,
          }}
        />
      </div>
    </>
  );
};

export default EventTypes;
