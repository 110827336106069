//Dependencies
import { styled } from "@mui/styles";
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
// ---UI Dependencies
import { Card, Button, Grid } from "@mui/material";
// ---Redux
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { ILoggedUserReducer } from "../../redux/reducers/LoggedUser";
// ---Components
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import SchoolForm from "../../components/SchoolForm";
// ---Config
import { useFormikConfig } from "./config/useFormikConfig";
// ---Services/Requests
import { fetchSchoolById, updateSchool } from "../../services/schools";

// -----------------------Interfaces
import { ERoles } from "../routes/role-catalog";
import { PayloadOpenModal, openModal } from "../../redux/actions/modal";
import { useLoading } from "../../hooks/useLoading";

// -----------------------STYLES
const LayoutContainer = styled(Card)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
}));

const UpdateSchool = () => {
  // -----------------------CONSTS, HOOKS, STATES
  const { handleClose, handleOpen } = useLoading();

  const history = useHistory();
  const formik = useFormikConfig(onSubmit);
  const dispatch = useDispatch();
  const id = (useParams() as any).id;
  const {
    loggedUser: { user },
  } = useSelector((store) => store.loggedUser as ILoggedUserReducer);

  useEffect(() => {
    initialConfig(id);
  }, [id]);

  // -----------------------MAIN METHODS
  function initialConfig(id: string) {
    fetchSchoolById(id)
      .then(({ data }) => {
        formik.setFieldValue("name", data.name);
        formik.setFieldValue("address", data.address);
        formik.setFieldValue("district", data.district._id);
        formik.setFieldValue(
          "totalStudentsEnrollment",
          data.totalStudentsEnrollment,
        );
        formik.setFieldValue("gradeLevels", data.gradeLevels);
        data.zipCode && formik.setFieldValue("zipCode", data.zipCode);
        data.state && formik.setFieldValue("state", data.state);
        if (user.role?.code === ERoles.RL001) {
          formik.setFieldValue("district", user?.district);
        }
      })
      .catch(() => {
        history.replace("/schools");
      });
  }

  async function onSubmit(values: any, { setFieldError }: any) {
    if (values.gradeLevels.length <= 0) {
      return setFieldError("gradeLevels", "Grade Leveles is required.");
    }
    try {
      handleOpen();
      const data = { ...formik.values, role: user!.role!.code };
      await updateSchool(data, id);
      handleSuccessModal();
    } catch {
      handleErrorModal();
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Success",
      description: `School updated successfully`,
      action: () => history.goBack(),
      hideSecondaryButton: true,
      primaryButtonText: "ok",
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Error updating School`,
    };
    dispatch(openModal(modalConfig));
  }

  // ------------------------- RENDER
  return (
    <>
      <PageTitle title="Edit School" />
      <MainLayout>
        <LayoutContainer>
          <SchoolForm formik={formik} />
          <Grid
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            container
            spacing={3}
            justifyContent="flex-end"
          >
            <Button onClick={() => history.goBack()} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={() => formik.handleSubmit()}
              sx={{ marginLeft: "10px" }}
              variant="contained"
            >
              SAVE
            </Button>
          </Grid>
        </LayoutContainer>
      </MainLayout>
    </>
  );
};

export default UpdateSchool;
