import { Checkbox, Typography } from "@mui/material";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import { paletteColors } from "../../styles/theme";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu, CustomSort } from "../../components/DataGrid";
import { displayToast } from "../../utils/helpers/displayToast";
import { LOCATION_TYPES_INDOOR, LOCATION_TYPES_OUTDOOR } from "../../constants";
import { ISelectOption } from "../../components/Inputs/Select";
import { deleteLocation, fetchLocations } from "../../services/locations";
import { useSelector } from "../../redux/typedHooks";

interface ILocation {
  id: string;
  name: string;
  school: string;
  description: string;
  indoor: boolean;
  type: string;
  active: boolean;
}

const Locations: React.FC = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const [loading, setLoader] = useState(false);
  const [types, setTypes] = useState<any>();
  const [roomT, setRoomT] = useState<any>();
  const [search, setSearch] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [deleteLocationModal, setDeleteLocationModal] = useState(false);
  const allTypes = { value: "ALL TYPES", name: "ALL TYPES" };

  const handleCloseModals = (): void => {
    setDeleteLocationModal(false);
  };

  const [locations, setLocations] = useState<ILocation[]>([]);
  const renderModalMenu = (params: GridRenderCellParams) => {
    const detailsOptionsList = [
      {
        name: "Delete Locations",
        action: async () => {
          setDeleteLocationModal(true);
          setSelectedLocation({
            id: params.id.toString(),
            active: !params.row.active,
          });
        },
      },
      {
        name: "Edit Location",
        action: () => {
          history.push("/update-location/" + params.id.toString());
        },
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  };

  async function fetchData() {
    try {
      setLoader(true);
      const response = await fetchLocations();
      const filteredLocations = response.data.payload.fixedLocations.filter(
        (location: any) => {
          return location.school === user.school._id;
        },
      );
      setLocations([...filteredLocations]);
    } catch (error) {
      displayToast("error", "Error obtaining locations");
    } finally {
      setLoader(false);
    }
  }

  const handleLocationDelete = async () => {
    if (selectedLocation) {
      try {
        const response = await deleteLocation(
          selectedLocation.id,
          selectedLocation.active,
        );
        if (response) {
          fetchData();
          setLoader(false);
          displayToast("success", "Location deleted");
          setSelectedLocation("");
          handleCloseModals();
        }
      } catch (err) {
        displayToast("error", "Error deleting location");
      }
    }
  };

  function setLocationTypes() {
    const indoor: ISelectOption[] = LOCATION_TYPES_INDOOR.map((value) => {
      return {
        value,
        name: value,
      };
    });
    const outdoor: ISelectOption[] = LOCATION_TYPES_OUTDOOR.map((value) => {
      return {
        value,
        name: value,
      };
    });
    setTypes([allTypes, ...indoor, ...outdoor]);
  }

  useEffect(() => {
    setLocationTypes();
    setRoomT("ALL TYPES");
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return <Typography>{params.value}</Typography>;
      },
    },
    {
      type: "boolean",
      headerName: "Location",
      field: "indoor",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.value === true ? (
              <Typography>Indoor</Typography>
            ) : (
              <Typography>Outdoor</Typography>
            )}
          </>
        );
      },
    },
    {
      headerName: "Type",
      field: "type",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>{renderModalMenu(params)}</>;
      },
    },
  ];

  const handleNavigation = () => {
    history.push("/register-location");
  };

  const handleFilter = () =>
    locations
      ?.filter(
        (location) =>
          search === "" ||
          location.name.toLowerCase().includes(search.toLowerCase()) ||
          !search,
      )
      .filter(
        (location) => location?.type?.includes(roomT) || roomT === "ALL TYPES",
      );

  return (
    <>
      <PageTitle title="Locations" />
      <div
        style={{ marginTop: "115px", marginLeft: "32px", marginRight: "32px" }}
      >
        <DataGrid
          columns={columns}
          rows={handleFilter()}
          loading={loading}
          toolbarProps={{
            buttonText: "Add new Location",
            buttonAction: handleNavigation,
            filterOptions: types,
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              setRoomT(event.target.value as string);
            },
          }}
        />
      </div>
      <ConfirmationModal
        open={deleteLocationModal}
        title={`DELETE Location`}
        text={`Are you sure you want to DELETE Location?`}
        cancelButtonAction={handleCloseModals}
        confirmButtonAction={handleLocationDelete}
        cancelButtonProps={{
          color: "primary",
          variant: "outlined",
        }}
        confirmButtonProps={{
          variant: "contained",
          style: {
            backgroundColor: paletteColors.error,
            color: "#FFF",
            "&:hover": {
              backgroundColor: paletteColors.errorSecondary,
            },
          },
        }}
        confirmButtonLoading={loading}
      />
    </>
  );
};

export default Locations;
