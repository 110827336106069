import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { modalStyles } from "../../TriggerEvent/TriggerEventModal/config/styles";
import React, { useState } from "react";
import { useSelector } from "../../../redux/typedHooks";

import { updateAlertInProgress } from "../../../services/alertsInProgress";
import { useParams } from "react-router-dom";
import { displayToast } from "../../../utils/helpers/displayToast";
import { AlertStatus } from "../../../constants";
import { paletteColors } from "../../../styles/theme";

interface IStopAlertModalProps {
  open: boolean;
  setClose: () => void;
  refetch: () => void;
}

export const StopAlertModal: React.FC<IStopAlertModalProps> = ({
  open,
  setClose,
  refetch,
}) => {
  const { id } = useParams<{ id: string }>();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const [submitting, setSubmitting] = useState(false);

  const handleStopSubbmit = async () => {
    try {
      setSubmitting(true);
      const form = {
        alertStatus: AlertStatus.Stopped,
        alertId: id,
        school: user.school._id,
        closingTime: new Date(),
        closingUserId: user._id,
      };

      await updateAlertInProgress(form);
      displayToast("success", "Alert Stopped Succesfully");
      setClose();
      refetch();
    } catch (error: any) {
      displayToast("error", "Error sending acknowledgment");
      console.error(
        "Error sending acknowledgment:",
        error.response?.data?.message || error.message,
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={setClose} aria-labelledby="dialog-modal">
      <DialogTitle sx={modalStyles.dialogTitle}>
        Are you sure to STOP this Alert?
      </DialogTitle>
      <DialogContent style={{ paddingTop: 20 }}>
        <DialogActions sx={modalStyles.dialogButtonsWrapper}>
          <Button
            color="primary"
            onClick={() => {
              setClose();
            }}
            disabled={false}
            variant="outlined"
          >
            Cancel
          </Button>

          <Button
            disabled={submitting}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: submitting
                ? paletteColors.lightRed
                : paletteColors.error,
              color: "#FFF",
            }}
            onClick={() => {
              handleStopSubbmit();
            }}
          >
            Confirm
            {submitting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                size={24}
              />
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
