import { GridColDef } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu } from "../../components/DataGrid";
import { displayToast } from "../../utils/helpers/displayToast";
import { SafetyAlertTypes } from "../../constants";
import { ISelectOption } from "../../components/Inputs/Select";
import { useSelector } from "../../redux/typedHooks";
import { fetchAlertsInProgress } from "../../services/alertsInProgress";
import { useHistory } from "react-router-dom";
import { AlertInProgressColumns } from "./config/columns";
import { getAllEvents } from "../../services/eventInProgress";
import fileDownload from "js-file-download";
import { downloadAlertReport, downloadReport } from "../../services/reports";

export interface IAlertInProgress {
  _id: string;
  studentName: string;
  location: {
    name: string;
  };
  alertCreatedBy: string;
  emergencyType: {
    name: string;
    _id: string;
  };
  comments: string;
  creationTime: string;
  schoolId: string;
  alertStatus: string;
}

const AlertInProgress: React.FC = () => {
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const history = useHistory();

  const [types, setTypes] = useState<any>();
  const [emergencyType, setEmergencyType] = useState<any>();
  const [search, setSearch] = useState<string>("");
  const allTypes = { value: "All Types", name: "All Types" };
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState<IAlertInProgress[]>([]);

  async function fetchData() {
    try {
      const response = await fetchAlertsInProgress(user.school._id);

      setReports([
        ...response.data.payload.map((alertInProgress: any) => ({
          ...alertInProgress,
          id: alertInProgress?._id || alertInProgress.id,
        })),
      ]);
    } catch (error) {
      displayToast("error", "Error obtaining reports");
    } finally {
      setIsLoading(false);
    }
  }

  async function setLocationTypes() {
    const response = await getAllEvents();
    if (response.data.success) {
      const eventsToSet = response.data.payload.map((event: any) => {
        return {
          value: event.name,
          name: event.name,
        };
      });
      setTypes([allTypes, ...eventsToSet]);
    }
  }

  useEffect(() => {
    setLocationTypes();
    setEmergencyType("All Types");
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
  function renderModalMenu(id: string) {
    const detailsOptionsList = [
      {
        name: "View Alert In Progress",
        action: () => history.push(`/alerts-in-progress/${id}`),
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  }

  const actionsColumns: GridColDef = {
    headerName: "ACTIONS",
    field: "",
    hideSortIcons: true,
    renderCell: (data) => {
      return renderModalMenu(data.row._id);
    },
  };

  const handleFilter = () =>
    reports
      ?.filter(
        (report) =>
          search === "" ||
          report.studentName.toLowerCase().includes(search.toLowerCase()) ||
          !search,
      )
      .filter(
        (report) =>
          report?.emergencyType.name
            ?.toLowerCase()
            .includes(emergencyType.toLowerCase()) ||
          emergencyType === "All Types",
      );
  return (
    <>
      <PageTitle title="Alert In Progress" />
      <div
        style={{ marginTop: "115px", marginLeft: "32px", marginRight: "32px" }}
      >
        <DataGrid
          disableSelectionOnClick
          columns={AlertInProgressColumns(actionsColumns)}
          rows={handleFilter()}
          loading={isLoading}
          toolbarProps={{
            filterOptions: types,
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              setEmergencyType(event.target.value as string);
            },
            hideIcons: true,
          }}
        />
      </div>
    </>
  );
};

export default AlertInProgress;
