/**
 * Genera una cadena aleatoria de la longitud indicada
 * @param {number} length Longitud de la randomString
 * @returns {string}
 */
function randomString(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

/**
 * Genera una cadena aleatoria y única.
 * @returns {string} una cadena única
 */
function uniqueString(): string {
  const uniqueKey = new Date().getDate();
  const randStr = randomString(4);
  return randStr + uniqueKey;
}

export const generate = {
  randomString,
  uniqueString,
};
