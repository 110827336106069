interface IDisabledInitialState {
  isDisabled: boolean;
  errorMsg: string;
}

const initialState: IDisabledInitialState = {
  isDisabled: false,
  errorMsg: "",
};

export enum disabledActions {
  USER_IS_DISABLED = "UserDisabled",
  SCHOOL_IS_DISABLED = "SchoolDisabled",
  DISTRICT_IS_DISABLED = "DistrictDisabled",
}

export const DisabledReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case disabledActions.USER_IS_DISABLED:
      return {
        isDisabled: true,
        errorMsg: "User is disabled, contact your admin",
      };
    case disabledActions.SCHOOL_IS_DISABLED:
      return {
        isDisabled: true,
        errorMsg: "School is disabled, contact your admin",
      };
    case disabledActions.DISTRICT_IS_DISABLED:
      return {
        isDisabled: true,
        errorMsg: "District is disabled, contact your admin",
      };
    default:
      return state;
  }
};
