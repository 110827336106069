import * as yup from "yup";

export interface ITriggerEventForm {
  eventId: string;
  headcount: number | undefined;
  emergencyType: string;
}

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be a number value.";

const MIN_MESSAGE = (value: number): string => `The min value is ${value}`;
const MAX_MESSAGE = (value: number): string => `The max value is ${value}`;

const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

export const triggerEventSchema = yup.object().shape({
  eventId: yup
    .string()
    .typeError(IS_STRING)
    .required(REQUIRED_MESSAGE("Event Type")),
  headcount: yup.number().min(1).typeError(IS_NUMBER).nullable(),
  emergencyType: yup.string().required(REQUIRED_MESSAGE("Event Type")),
});
export function IS_VALID(data: any) {
  try {
    triggerEventSchema.validate(data);
    return true;
  } catch (err) {
    return false;
  }
}
