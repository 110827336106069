import { Button, CircularProgress, Grid } from "@mui/material";

import { AxiosError } from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Form, { IFields } from "../../components/Form";
import { TextInput } from "../../components/Inputs";
import Select, { ISelectOption } from "../../components/Inputs/Select";
import { LOCATION_TYPES_INDOOR, LOCATION_TYPES_OUTDOOR } from "../../constants";
import { useSelector } from "../../redux/typedHooks";
import {
  getLocationById,
  LocationRegister,
  LocationUpdate,
} from "../../services/locations";
import { displayToast } from "../../utils/helpers/displayToast";
import { axiosErrorHandling } from "../../utils/helpers/error";

import {
  ILocationRegistration,
  ILocationUpdate,
  LOCATION_SCHEMA,
} from "./schema";
import { useStyles } from "./styles";

interface ILocationFormProps {
  action: "update" | "register";
  locationId: string;
}

const LocationForm: React.FC<ILocationFormProps> = ({ action, locationId }) => {
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const [loading, setLoader] = useState<boolean>(false);
  const [types, setTypes] = useState<ISelectOption[]>();
  const [selectedType, setSelectedType] = useState<any>({
    name: "Offices",
    type: "indoor",
    value: "Offices",
  });
  const [updateLocation, setUpdateLocation] = useState<ILocationRegistration>({
    name: "",
    school: user?.school?._id,
    description: "",
    indoor: false,
    type: "",
  });
  const history = useHistory();
  useEffect(() => {
    if (action === "update") {
      getLocationToUpdate();
    }
    setLocationTypes();
  }, []);
  function setLocationTypes() {
    const indoor: ISelectOption[] = LOCATION_TYPES_INDOOR.map((value) => {
      return {
        value,
        name: value,
        type: "indoor",
      };
    });
    const outdoor: ISelectOption[] = LOCATION_TYPES_OUTDOOR.map((value) => {
      return {
        value,
        name: value,
        type: "outdoor",
      };
    });
    setTypes([...indoor, ...outdoor]);
  }
  const handleRegistration = async (fields: IFields): Promise<void> => {
    try {
      setLoader(true);
      const formFields = fields as unknown as ILocationRegistration;
      const submitionForm = {
        name: formFields.name,
        description: formFields.description,
        school: user?.school?._id,
        indoor: selectedType.type === "indoor" ? true : false,
        type: formFields.type,
      };
      const response = await LocationRegister(submitionForm);
      if (response.data.success) {
        displayToast(
          "success",
          "Location registered successfully ,relocating to location list now",
        );
        setTimeout(() => {
          history.push("/locations");
        }, 2000);
      }
    } catch (error) {
      const err = error as AxiosError;
      axiosErrorHandling("There has been a problem creating the location", err);
    } finally {
      setLoader(false);
    }
  };
  const handleUpdate = async (fields: IFields): Promise<void> => {
    try {
      const formFields = fields as unknown as ILocationUpdate;
      const submitionForm = {
        _id: updateLocation?._id,
        name: formFields.name,
        school: user?.school?._id,
        description: formFields.description,
        indoor: selectedType.type === "indoor" ? true : false,
        type: formFields.type,
      };

      const response = await LocationUpdate(submitionForm);
      if (response.data.success) {
        displayToast(
          "success",
          "Location updated successfully, relocating to location list now",
        );
        setTimeout(() => {
          history.push("/locations");
        }, 2000);
      }
    } catch (error) {
      const err = error as AxiosError;
      axiosErrorHandling("There has been a problem updating the location", err);
    } finally {
      setLoader(false);
    }
  };

  const getLocationToUpdate = async () => {
    const location = await getLocationById(locationId);
    setUpdateLocation({ ...location.data.payload.location });
  };
  const handleSubmit = (fields: IFields) => {
    if (action === "register") {
      handleRegistration(fields);
    } else {
      handleUpdate(fields);
    }
  };
  const classes = useStyles();
  return (
    <div className={classes.formContainer}>
      <Form
        onSubmit={handleSubmit}
        fields={LOCATION_SCHEMA}
        initialValues={updateLocation}
      >
        {({ handleSubmit, handleChange, handleBlur, handleClear, fields }) => (
          <>
            <div className={classes.form}>
              <div className={classes.gridFormAdjuster}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Grid item xs={12} className={`${classes.bigItem} `}>
                    <TextInput
                      disabled={loading}
                      style={{ width: "50%" }}
                      label="Name"
                      value={fields.name.value}
                      onBlur={handleBlur("name")}
                      onChange={handleChange("name")}
                      error={!!fields.name.errors.length}
                      helperText={fields.name.errors.join(". ")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ marginBottom: "120px" }}
                    className={`${classes.bigItem}`}
                  >
                    <TextInput
                      required
                      disabled={loading}
                      style={{ width: "50%" }}
                      label="Description"
                      value={fields.description.value}
                      onBlur={handleBlur("description")}
                      onChange={handleChange("description")}
                      multiline
                      rows={4}
                      error={!!fields.description.errors.length}
                      helperText={fields.description.errors.join(". ")}
                      fullWidth
                      className={classes.commentsInput}
                    />
                  </Grid>
                  <Grid item xs={12} className={`${classes.bigItem}`}>
                    <Select
                      disabled={loading}
                      label="Type"
                      value={fields.type.value}
                      onChange={handleChange("type")}
                      onClick={(event) => {
                        const currentType = types?.find((type) => {
                          return type.value === fields.type.value;
                        });
                        setSelectedType(currentType);
                      }}
                      required
                      fullWidth
                      style={{ width: "71%" }}
                      error={!!fields.type.errors.length}
                      options={types}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={handleClear}
                  style={{ marginRight: "12px" }}
                >
                  clear
                  {loading && (
                    <CircularProgress className={classes.loader} size={24} />
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Save
                  {loading && (
                    <CircularProgress className={classes.loader} size={24} />
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
export default LocationForm;
