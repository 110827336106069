import { useState } from "react";
import { AlertInProgressSchema } from "../pages/UsersSafetyC/StudentSafetyAlertModal/schema";
import { addAlertInProgress } from "../services/alertsInProgress";
import { displayToast } from "../utils/helpers/displayToast";

interface UseStudentSafetyAlertProps {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

export const useStudentSafetyAlert = (props?: UseStudentSafetyAlertProps) => {
  const handleSubmit = async (
    fields: AlertInProgressSchema,
    userId: string,
    schoolId: string,
  ) => {
    try {
      const form = {
        ...fields,
        school: schoolId,
        alertCreatedBy: userId,
        locationName: !fields.location ? fields.locationName : null,
      };
      if (fields.location === "" && fields.locationName === "") {
        return;
      }
      await addAlertInProgress(form);
      displayToast("success", "Student safety alert created successfully");
      props?.onSuccess?.();
    } catch (error: any) {
      displayToast(
        "error",
        "Error creating student safety alert : " +
          error?.response?.data?.message,
      );
      console.error("Error submitting student safety alert:", error);
      props?.onError?.(error);
    } finally {
    }
  };

  return {
    handleSubmit,
  };
};
