import { makeStyles, createStyles } from "@mui/styles";
import { createTheme } from "@mui/material";

export const defaultTheme = createTheme();
export const useStylesDataGrid = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: "white",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#E2E2E280",
        },
        "& .MuiDataGrid-row": {
          backgroundColor: "",
        },
        margin: "8px",
        width: "100%",
      },
    }),
  { defaultTheme },
);

export const styles = {
  box: {
    height: "100%",
    maxWidth: "500px",
    "& .MuiTextField-root": { m: 1, width: "100%" },
    flex: 1,
    justifyContent: "center",
  },
};
