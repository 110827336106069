// ---Dependencies
import { Grid, Box, Typography, Paper, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
// ---Redux
import { useSelector } from "../../redux/typedHooks";
import PageTitle from "../../components/DashboardLayout/PageTitle";
// ---Components
import { styles } from "./styles";
import { NumberOfSchools } from "./components/NumberofSchools";
import { SchoolsMostEvents } from "./components/SchoolsWithMostEvents";
import { SchoolsLeastEvents } from "./components/SchoolsWithLeastEvents";
import { DrillCount } from "./components/DrillCount";
import { EmergencyCount } from "./components/EmergencyCount";
import { TextInput } from "../../components/Inputs";
import {
  fetchSchoolsPaginated,
  getAverageEventDuration,
  getAvgSchoolperDistrict,
  getSchoolUserCount,
} from "../../services/schools";
import { msToTime } from "../../utils/helpers/msToTime";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(2),
  textAlign: "center",
  height: "48%",
  width: "100%",
  marginBottom: "12px",
}));

const DashboardSuperAdmin = () => {
  const [schools, setSchools] = React.useState<any>([
    {
      name: "None",
      value: "633c585a32f48f254bce78ce",
    },
  ]);
  const [schoolId, setSchoolId] = useState("");
  const [userCount, setUserCount] = useState(0);
  const [schoolAvg, setSchoolAvg] = useState(0);
  const [avgEvent, setAvgEvent] = useState("0");
  const cardsGrid = { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 };
  const handleChange = (event: any) => {
    setSchoolId(event.target.value as string);
  };

  useEffect(() => {
    fetchSchoolsPaginated().then((response) => {
      const schoolList = response.data.map((school) => {
        return { name: school.name, value: school._id };
      });
      setSchools(schoolList);
    });
  }, []);
  useEffect(() => {
    getAvgSchoolperDistrict().then((response) => {
      setSchoolAvg(response.data.data);
    });
  }, []);

  useEffect(() => {
    getSchoolUserCount(schoolId)
      .then((response) => {
        setUserCount(response.data.data.toString());
      })
      .catch((err) => {
        console.log(err);
      });
  }, [schoolId]);
  useEffect(() => {
    getAverageEventDuration()
      .then((response) => {
        const avg = response.data.data;
        if (avg.length > 0) {
          const sumTime = response.data.data.reduce(
            (acum: number, time: any) => {
              return (acum += time.avgTime);
            },
            0,
          );
          const promTime = sumTime / avg.length;
          const result = msToTime(promTime);
          setAvgEvent(result);
        } else {
          setAvgEvent("0");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <PageTitle title="Dashboard" />
      <Box sx={styles.gridLayout}>
        <Grid container spacing={2}>
          <Grid container item spacing={4} xs={12}>
            <Grid item xs={12} sm={4}>
              <DrillCount schoolId={schoolId} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberOfSchools />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EmergencyCount schoolId={schoolId} />
            </Grid>
          </Grid>
          <Grid container item spacing={4} xs={12} sx={{ height: "175px" }}>
            <Grid item xs={12} sm={4}>
              <SchoolsMostEvents />
            </Grid>
            <Grid item container direction="row" xs={12} sm={4}>
              <Item>
                <Typography variant="subtitle1" align="center" gutterBottom>
                  Average Number of Users per School
                </Typography>
                <Typography
                  style={{
                    fontSize: "32px",
                    width: "100%",
                    marginTop: "40px",
                  }}
                  variant="subtitle2"
                  align="center"
                >
                  {Math.trunc(schoolAvg)}
                </Typography>
              </Item>
              <Item>
                <Typography variant="h6" align="center" gutterBottom>
                  Average Event Duration
                </Typography>
                <Typography
                  style={{
                    fontSize: "32px",
                    width: "100%",
                    marginTop: "40px",
                  }}
                  variant="subtitle2"
                  align="center"
                >
                  {avgEvent} hrs
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SchoolsLeastEvents />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardSuperAdmin;
