// ---Dependencies
import { Autocomplete, TextField } from "@mui/material";
import { ReactElement } from "react";
import { ISchool } from "../../../../../models/School";
// ---Types
import { IUserFormProps } from "../../../../../models/Users";
import { ICatalogs } from "../../../../../pages/CreateUserSuperadmin/config/useCatalogs";
// ---Components
// ---UI Dependencies
// ---Custom Hooks
// ---Redux
// ---Config
// ---Assets
// ---Utils
// ---Constants
// ---Services/Requests
// ---Images

interface Props extends IUserFormProps {
  localRole?: string | null;
  schools: ICatalogs["schools"];
}
/**
 * SchoolsInput Component: Autocomplete Input field that map the
 * schools in the options along their zip code and save the selected option
 * @param {Props} props - Formik object and list of schools
 * @returns {ReactElement}
 */
export function SchoolsInput({ formik, schools }: Props): ReactElement {
  // -----------------------MAIN METHODS
  function onChangeAutoComp(_: any, value: ISchool | null) {
    formik.setFieldValue("school", value);
  }
  const showError = !formik.values.school;
  // -----------------------RENDER
  return (
    <Autocomplete
      options={schools}
      value={formik.values.school}
      onChange={onChangeAutoComp}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <h5 {...(props as any)}>
            {option?.name} (ZIP Code: {option?.zipCode})
          </h5>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="School*"
          variant="outlined"
          error={showError}
          helperText={showError ? "Select an option" : ""}
        />
      )}
    />
  );
}
