import { paletteColors } from "../../../styles/theme";

const styles = {
  button: {
    height: "36px",
    minWidth: "108px",
    fontWeight: 400,
    marginLeft:'12px',
    width: {
      sm: "unset",
      xs: "100%",
    },
  },
  textField: {
    maxWidth: "328px",
    "& .MuiSvgIcon-root": {
      marginRight: "4px",
    },
  },
  filter: {
    display: "flex",
    marginTop: "8px",
    marginRight: "16px",
  },
  optionsWrapper: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  icon: {
    color: paletteColors.icon,
    padding: "5px",
    marginLeft: "10px",
    minWidth: "unset",
    "& > span": {
      marginRight: 0,
    },
  },
  iconsWrapper: {
    display: "flex",
    marginRight: "28px",
  },
  toolbar: {
    display: "flex",
    padding: "24px 22px 30px 16px",
    "@media (min-width: 500px) and (max-width: 700px)": {
      flexDirection: "row",
    },
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  searchField: {
    "@media (min-width: 500px) and (max-width: 700px)": {
      width: "200px",
      minWidth: "160px",
    },
    width: {
      lg: "328px",
      sm: "200px",
      xs: "100%",
    },
    minWidth: {
      lg: "328px",
      sm: "160px",
      xs: "100%",
    },
  },
  selector: {
    width: {
      xl: "226px",
      md: "180px",
    },
  },
  selectorWrapper: {
    "@media (min-width: 500px) and (max-width: 700px)": {
      maxWidth: "180px",
      marginTop: 0,
      marginBottom: 0,
      marginLeft: "32px",
    },
    marginTop: {
      sm: 0,
      xs: "24px",
    },
    marginBottom: {
      sm: 0,
      xs: "24px",
    },
    marginLeft: {
      sm: "32px",
      xs: "0px",
    },
    width: {
      lg: "unset",
      sm: "100%",
      xs: "100%",
    },
    maxWidth: {
      sm: "180px",
      xs: "100%",
    },
  },
  searchIcon: {
    marginRight: "15px",
  },
} as const;

export default styles;
