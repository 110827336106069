import * as yup from "yup";

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be a number value.";
const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

/**
 * Yup Schema wich describe the data constrains of an specifc object
 */
export const validationSchema = yup.object().shape({
  name: yup.string().typeError(IS_STRING).required(REQUIRED_MESSAGE("name")),
  address: yup
    .string()
    .typeError(IS_STRING)
    .required(REQUIRED_MESSAGE("address")),
  district: yup
    .string()
    .typeError(IS_STRING)
    .required(REQUIRED_MESSAGE("District")),
  gradeLevels: yup
    .array()
    .of(yup.string().required(REQUIRED_MESSAGE("Grade Levels")))
    .required(REQUIRED_MESSAGE("Grade Levels")),
  zipCode: yup
    .string()
    .matches(/^\d{5}$/, "The US zip code must contain 5 digits")
    .required(REQUIRED_MESSAGE("Zip Code")),
  state: yup.string().typeError(IS_STRING).required(REQUIRED_MESSAGE("State")),
  totalStudentsEnrollment: yup.number().typeError(IS_NUMBER),
});
