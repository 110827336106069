// ---Dependencies
import React, { ReactElement } from "react";
import { RouteChildrenProps } from "react-router";
// ---UI Dependencies
import {
  DashboardOutlined,
  School,
  LocationCity,
  Map,
  Group,
  GroupOutlined,
  Home,
  Article,
  AddAlert,
  Category,
  HourglassBottom,
  MedicalServices,
} from "@mui/icons-material";
// ---Constants
import { ERoles } from "./role-catalog";
// ---Components
import DashboardSuperAdmin from "../DashboardSuperAdmin";
import EventInProgress from "../EventInProgress";
import UsersSuperAdmin from "../UsersSuperAdmin";
import UsersDistrictAdmin from "../UsersDistrictAdmin";
import Districts from "../Districts";
import Schools from "../Schools";
import DashboardSafetyCommander from "../DashboardSafetyCommander";
import Locations from "../Locations";
import LocationsRegister from "../LocationsRegister";
import CreateSchool from "../CreateSchool";
import UpdateSchool from "../UpdateSchool";
import Reports from "../Reports";

// ---Constants

import CreateDistrict from "../CreateDistrict";
import UpdateDistrict from "../UpdateDistrict";
import CreateUserSuperadmin from "../CreateUserSuperadmin";
import UpdateUserSuperAdmin from "../UpdateUserSuperAdmin";
import UsersSafetyC from "../UsersSafetyC";
import LocationUpdate from "../UpdateLocation/LocationUpdate";
import CreateUserSafetyC from "../CreateUserSafetyC";
import UpdateUserSafetyC from "../UpdateUserSafetyC";
import DistrictsDashboard from "../DistrictsDashboard";
import UsersAlerts from "../UsersAlerts/UsersAlerts";
import AlertInProgress from "../AlertsInProgress/AlertsInProgress";
import ViewAlertInProgress from "../ViewAlertInProgress";
import EventTypes from "../EventTypes/EventTypes";
import CreateEventTypeC from "../CreateEventTypeC";

interface IPage {
  component?: React.FC<RouteChildrenProps | null>;
  displayName: string;
  route?: string;
  public: boolean;
  exact: boolean;
  roles: Array<ERoles>;
  iconComponent?: ReactElement;
  isMenuItem?: boolean;
  isBottom?: boolean;
  children?: IPage[];
  render?: any;
}

export const {
  RL000, // SUPERADMIN
  RL001, // DISTRICT_ADMIN
  RL002, // SAFETY_COMMANDER
  RL003, // SAFETY_TEAM
  RL004, // TEACHER
  RL005, // ENROLLMENT LEADER
} = ERoles;

const routesDashBoard = [
  {
    component: EventInProgress,
    displayName: "Event in Progress",
    isMenuItem: true,
    roles: [RL003, RL004],
    iconComponent: <DashboardOutlined />,
    route: "/dashboard",
    public: false,
    exact: true,
  },
  {
    component: DashboardSafetyCommander,
    displayName: "Dashboard",
    isMenuItem: true,
    roles: [RL002, RL005],
    iconComponent: <Home />,
    route: "/dashboard",
    public: false,
    exact: true,
  },
];

const Routes: Array<IPage> = [
  // --- DASHBOARDS
  ...routesDashBoard,

  // --------PLATAFORM MANAGEMENT--------
  {
    component: DashboardSuperAdmin,
    displayName: "Dashboard",
    isMenuItem: true,
    roles: [RL000],
    iconComponent: <DashboardOutlined />,
    route: "/dashboard",
    public: false,
    exact: true,
  },

  // --------DISTRICT MANAGEMENT--------
  {
    component: Districts,
    displayName: "Districts",
    isMenuItem: true,
    roles: [RL000],
    iconComponent: <LocationCity />,
    route: "/districts",
    public: false,
    exact: true,
  },
  {
    component: DistrictsDashboard,
    displayName: "Districts",
    isMenuItem: true,
    roles: [RL001],
    iconComponent: <LocationCity />,
    route: "/dashboard",
    public: false,
    exact: true,
  },
  {
    component: CreateDistrict,
    displayName: "Create Districts",
    isMenuItem: false,
    roles: [RL000],
    iconComponent: <LocationCity />,
    route: "/create-district",
    public: false,
    exact: true,
  },
  {
    component: UpdateDistrict,
    displayName: "Update Districts",
    isMenuItem: false,
    roles: [RL000, RL001],
    iconComponent: <LocationCity />,
    route: "/update-district/:id",
    public: false,
    exact: true,
  },
  // --------DISTRICT MANAGEMENT--------
  // --------SCHOOL MANAGEMENT--------
  {
    displayName: "Schools",
    component: Schools,
    isMenuItem: true,
    roles: [RL000, RL001],
    iconComponent: <School />,
    route: "/schools",
    public: false,
    exact: true,
  },
  {
    displayName: "Create Schools",
    component: CreateSchool,
    isMenuItem: false,
    roles: [RL000, RL001],
    route: "/create-school",
    public: false,
    exact: true,
  },
  {
    displayName: "Update Schools",
    component: UpdateSchool,
    isMenuItem: false,
    roles: [ERoles.RL000, ERoles.RL001],
    route: "/update-school/:id",
    public: false,
    exact: true,
  },

  // --------SCHOOL MANAGEMENT--------
  {
    component: UsersSuperAdmin,
    displayName: "Users",
    isMenuItem: true,
    roles: [RL000],
    iconComponent: <Group />,
    route: "/users-superadmin",
    public: false,
    exact: true,
  },
  {
    component: UsersDistrictAdmin,
    displayName: "Users",
    isMenuItem: true,
    roles: [RL001],
    iconComponent: <Group />,
    route: "/users-districtadmin",
    public: false,
    exact: true,
  },
  {
    component: CreateUserSuperadmin,
    displayName: "Create User",
    isMenuItem: false,
    roles: [RL000, RL001],
    iconComponent: <LocationCity />,
    route: "/create-user-superadmin",
    public: false,
    exact: true,
  },
  {
    component: UpdateUserSuperAdmin,
    displayName: "Update User",
    isMenuItem: false,
    roles: [RL000, RL001],
    iconComponent: <LocationCity />,
    route: "/update-user-superadmin/:id",
    public: false,
    exact: true,
  },
  {
    component: UpdateUserSafetyC,
    displayName: "Update User",
    isMenuItem: false,
    roles: [RL002],
    iconComponent: <LocationCity />,
    route: "/update-user/:id",
    public: false,
    exact: true,
  },
  {
    component: UsersSafetyC,
    displayName: "Users",
    isMenuItem: true,
    roles: [RL002],
    iconComponent: <Group />,
    route: "/users",
    public: false,
    exact: true,
  },
  {
    component: CreateUserSafetyC,
    displayName: "Create User",
    isMenuItem: false,
    roles: [RL002],
    iconComponent: <LocationCity />,
    route: "/create-user",
    public: false,
    exact: true,
  },
  // --------SCHOOL MANAGEMENT--------
  // Locations
  {
    component: Locations,
    displayName: "Locations",
    isMenuItem: true,
    roles: [RL002],
    iconComponent: <Map />,
    route: "/locations",
    public: false,
    exact: true,
  },
  {
    displayName: "Alert In Progress",
    component: AlertInProgress,
    isMenuItem: true,
    roles: [RL002, RL003, RL004],
    iconComponent: <HourglassBottom />,
    route: "/alerts-in-progress",
    public: false,
    exact: true,
  },
  {
    displayName: "Event Types",
    component: EventTypes,
    isMenuItem: true,
    roles: [RL002],
    iconComponent: <MedicalServices />,
    route: "/event-types",
    public: false,
    exact: true,
  },
  {
    displayName: "LocationRegister",
    component: LocationsRegister,
    isMenuItem: false,
    roles: [RL002],
    iconComponent: <GroupOutlined />,
    route: "/register-location",
    public: false,
    exact: true,
  },
  {
    displayName: "LocationUpdate",
    component: LocationUpdate,
    isMenuItem: false,
    roles: [RL002],
    iconComponent: <GroupOutlined />,
    route: "/update-location/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Reports",
    component: Reports,
    isMenuItem: true,
    roles: [RL002, RL005],
    iconComponent: <Article />,
    route: "/reports",
    public: false,
    exact: true,
  },
  {
    displayName: "Alert Users",
    component: UsersAlerts,
    isMenuItem: true,
    roles: [RL002],
    iconComponent: <AddAlert />,
    route: "/alert-users",
    public: false,
    exact: true,
  },

  {
    displayName: "Add Emergency Event Types",
    component: CreateEventTypeC,
    isMenuItem: false,
    roles: [RL002],
    iconComponent: <Category />,
    route: "/add-event-type",
    public: false,
    exact: true,
  },
  {
    displayName: "Update Emergency Event Type",
    component: CreateEventTypeC,
    isMenuItem: false,
    roles: [RL002],
    iconComponent: <LocationCity />,
    route: "/update-event-type/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "View Alert In Progress",
    component: ViewAlertInProgress,
    isMenuItem: false,
    roles: [RL002, RL003, RL004],
    iconComponent: <DashboardOutlined />,
    route: "/alerts-in-progress/:id",
    public: false,
    exact: true,
  },
];

export default Routes;
