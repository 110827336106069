import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";

import { STATES_USA } from "../../constants";

import { IDistrictFormProps } from "./IDistrictFormProps";

const DistrictForm: React.FC<IDistrictFormProps> = ({ formik }) => {
  return (
    <Box
      sx={{
        maxWidth: "500px",
        marginTop: 8,
        "& .MuiTextField-root": { m: 1, width: "500px" },
        flex: 1,
      }}
    >
      <TextField
        label="Name*"
        variant="outlined"
        value={formik.values.name}
        error={!!formik.errors.name && !!formik.touched.name}
        helperText={
          !!formik.errors.name && !!formik.touched.name && formik.errors.name
        }
        onChange={formik.handleChange("name")}
      />
      <TextField
        label="District Number*"
        variant="outlined"
        value={formik.values.number}
        error={!!formik.errors.number && !!formik.touched.number}
        helperText={
          !!formik.errors.number &&
          !!formik.touched.number &&
          formik.errors.number
        }
        onChange={formik.handleChange("number")}
      />
      <Autocomplete
        value={formik.values.state}
        onChange={(e, value) => formik.setFieldValue("state", value)}
        options={STATES_USA}
        renderInput={(params) => (
          <TextField
            {...params}
            label="State*"
            variant="outlined"
            error={!!formik.errors.state && !!formik.touched.state}
            helperText={
              !!formik.errors.state &&
              !!formik.touched.state &&
              formik.errors.state
            }
          />
        )}
      />
      <TextField
        label="Zip Code*"
        variant="outlined"
        value={formik.values.zipCode}
        error={!!formik.errors.zipCode && !!formik.touched.zipCode}
        helperText={
          !!formik.errors.zipCode &&
          !!formik.touched.zipCode &&
          formik.errors.zipCode
        }
        onChange={formik.handleChange("zipCode")}
      />
      <TextField
        label="Point of Contact*"
        variant="outlined"
        value={formik.values.pointOfContact}
        error={
          !!formik.errors.pointOfContact && !!formik.touched.pointOfContact
        }
        helperText={
          !!formik.errors.pointOfContact &&
          !!formik.touched.pointOfContact &&
          formik.errors.pointOfContact
        }
        onChange={formik.handleChange("pointOfContact")}
      />
    </Box>
  );
};

export default DistrictForm;
