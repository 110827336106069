import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken } from "../config";

export const fetchEventsForReports = (
  school: string,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event/events",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { school },
  };
  return axios(config);
};

export const downloadReport = (eventId: string): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/current-event/report/excel/${eventId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  };
  return axios(config);
};

export const downloadAlertReport = (
  alertId: string,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/reports/alerts/excel/${alertId}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  };
  return axios(config);
};
export const fetchAlertsForReports = (
  school: string,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/reports?school=${school}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
