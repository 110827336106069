import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { openModal, PayloadOpenModal } from "../../../redux/actions/modal";
import { useDispatch } from "../../../redux/typedHooks";
import { fetchSchoolsPaginated } from "../../../services/schools";
import { ItemGrid } from "../../DashboardSafetyCommander/components/CustomCard";

export const NumberOfSchools = () => {
  const [schoolCount, setSchoolCount] = useState(0);
  const dispatch = useDispatch();
  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Error getting school information`,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    // dispatch(openModal(modalConfig));
  }
  useEffect(() => {
    fetchSchoolsPaginated()
      .then((response) => {
        const { data: schools } = response;
        setSchoolCount(schools.length);
      })
      .catch((err) => {
        handleErrorModal();
      });
  }, []);
  return (
    <>
      <ItemGrid>
        <Box height="100%">
          <Typography variant="h6" align="center" gutterBottom>
            Total Number of Schools
          </Typography>
          <Typography
            style={{
              fontSize: "32px",
              width: "100%",
              textAlign: "center",
              marginTop: "40px",
            }}
            variant="subtitle1"
            align="left"
          >
            {schoolCount}
          </Typography>
        </Box>
      </ItemGrid>
    </>
  );
};
