import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useStyles } from "./styles";
import { LabelField } from "./LabelField";
interface AlertDetailsProps {
  alert: {
    _id?: string;
    studentName: string;
    location: {
      name: string;
    };
    emergencyType: {
      name: string;
    };
    creationTime: Date;
    alertStatus: string;
    alertUpdatedTime?: Date;
    alertCreatedBy: {
      firstName: string;
      lastName: string;
    };
    updationTime?: Date;
    comments?: string;
    acknowledgedComments?: string;
    timeOfAcknowledgement?: Date;
    acknowledgedBy?: {
      firstName: string;
      lastName: string;
    };
    CloseTime?: Date;
    ClosingUserId?: string;
    school: {
      address: string;
      state: string;
      district: {
        name: string;
        zipCode: string;
      };
      name: string;
    };
  };
}

const AlertDetails: React.FC<AlertDetailsProps> = ({ alert }) => {
  const styles = useStyles();

  return (
    <Box sx={{ p: 3, border: "1px solid #ccc", borderRadius: "8px" }}>
      <Typography variant="h6" gutterBottom>
        Alert Details
      </Typography>

      <LabelField label="Student Name" value={alert.studentName} />
      <LabelField label="Comments" value={alert.comments} />
      <LabelField label="Status" value={alert.alertStatus} />
      <LabelField label="Event Type" value={alert.emergencyType.name} />
      <LabelField label="Location" value={alert.location?.name} />
      <LabelField
        label="Created By"
        value={`${alert.alertCreatedBy.firstName} ${alert.alertCreatedBy.lastName}`}
      />
      <LabelField
        label="Acknowledged By"
        value={
          alert.acknowledgedBy?.firstName
            ? `${alert.acknowledgedBy.firstName} ${alert.acknowledgedBy.lastName}`
            : undefined
        }
      />
      <LabelField label="Acknowledged At" value={alert.timeOfAcknowledgement} />
      <LabelField
        label="Acknowledged Comments"
        value={alert.acknowledgedComments}
      />
      <LabelField label="School Name" value={alert.school.name} />
      <LabelField label="School Address" value={alert.school.address} />
      <LabelField label="School District" value={alert.school.district.name} />
      <LabelField label="School State" value={alert.school.state} />
      <LabelField label="Zip Code" value={alert.school.district.zipCode} />
      <LabelField label="Created At" value={alert.creationTime} />
    </Box>
  );
};

export default AlertDetails;
