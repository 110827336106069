export const events = [
  {
    value: "632b724549b33ee37f66bcb9-Fire drill",
    name: "Fire drill",
  },
  {
    value: "632b727a49b33ee37f66bcba-Lockdown",
    name: "Lockdown",
  },
  {
    value: "632b729249b33ee37f66bcbb-Shelter in place",
    name: "Shelter in place",
  },
  {
    value: "632b72a749b33ee37f66bcbc-Hazmat",
    name: "Hazmat",
  },
  {
    value: "632b72b949b33ee37f66bcbd-Severe weather",
    name: "Severe weather",
  },
  {
    value: "632b72ce49b33ee37f66bcbe-Evacuate",
    name: "Evacuate",
  },
];
export const SafetyAlertType = [
  {
    value: "Fighting",
    name: "Fighting",
  },
  {
    value: "Medical Emergency",
    name: "Medical Emergency",
  },
  {
    value: "Emotional Breakdown",
    name: "Emotional Breakdown",
  },
];

export const emergencyType = [
  {
    value: "drill",
    name: "Drill",
  },
  {
    value: "emergency",
    name: "Emergency",
  },
];
