import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getSchoolEventCount } from "../../../services/schools";

import { ItemGrid } from "../../DashboardSafetyCommander/components/CustomCard";

export const SchoolsLeastEvents = () => {
  const [leastSchools, setleastSchools] = useState([]);
  useEffect(() => {
    getSchoolEventCount()
      .then((response) => {
        const sorted = response.data.data.sort(
          (a: any, b: any) => a.eventCount - b.eventCount,
        );
        const spliced = sorted.slice(0, 6);
        setleastSchools(spliced);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <ItemGrid>
      <Typography variant="h6" align="center" gutterBottom>
        Least Active Schools
      </Typography>
      {leastSchools.map((school: any) => {
        return (
          <Box
            flexDirection="row"
            display="flex"
            alignItems="flex-end"
            key={school._id}
          >
            <Typography
              style={{
                fontSize: "22px",
                marginRight: "12px",
              }}
              variant="subtitle1"
              align="left"
            >
              {school.school[0] !== undefined
                ? school.school[0]?.name
                : "Dummy"}
              :
            </Typography>
            <Typography marginBottom="2px" variant="subtitle1" align="left">
              {school.eventCount}
            </Typography>
          </Box>
        );
      })}
    </ItemGrid>
  );
};
