import { Autocomplete, Box, MenuItem, TextField } from "@mui/material";
import React, { Key } from "react";
import { emergencyType } from "../TriggerEvent/TriggerEventModal/constants/const";
import { useCatalogs } from "../CreateUserSafetyC/config/useCatalogs";
import { SchoolsInput } from "../CreateUserSuperadmin/UserFormSuperA/ConditionalForm/SchoolsInput/SchoolsInput";

type Props = {
  formik: any;
};

const EventTypeFormC: React.FC<Props> = ({ formik }) => {
  const catalogs = useCatalogs();
  return (
    <Box
      sx={{
        maxWidth: "500px",
        marginTop: 8,
        "& .MuiTextField-root": { m: 1, width: "500px" },
        flex: 1,
      }}
    >
      <TextField
        label="Name*"
        variant="outlined"
        value={formik.values.name}
        error={!!formik.errors.name && !!formik.touched.name}
        helperText={
          !!formik.errors.name && !!formik.touched.name && formik.errors.name
        }
        onChange={formik.handleChange("name")}
      />
      <TextField
        label="Description"
        variant="outlined"
        value={formik.values.description}
        error={!!formik.errors.description && !!formik.touched.description}
        helperText={
          !!formik.errors.description &&
          !!formik.touched.description &&
          formik.errors.description
        }
        onChange={formik.handleChange("description")}
      />
      <TextField
        label="Protocol Steps"
        variant="outlined"
        value={formik.values.protocolSteps}
        error={!!formik.errors.protocolSteps && !!formik.touched.protocolSteps}
        helperText={
          !!formik.errors.protocolSteps &&
          !!formik.touched.protocolSteps &&
          formik.errors.protocolSteps
        }
        onChange={formik.handleChange("protocolSteps")}
      />
      <TextField
        select
        label="Drill Emergency"
        value={formik.values.drillEmergency || ""}
        onChange={formik.handleChange("drillEmergency")}
        onBlur={formik.handleBlur("drillEmergency")}
        error={!!formik.errors.drillEmergency}
        helperText={formik.errors.drillEmergency}
        fullWidth
        sx={{ marginBottom: 2 }}
      >
        {emergencyType.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default EventTypeFormC;
