import * as yup from "yup";

export interface IAlertInProgressForm {
  emergencyType: string;
}

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be a number value.";

const MIN_MESSAGE = (value: number): string => `The min value is ${value}`;
const MAX_MESSAGE = (value: number): string => `The max value is ${value}`;

const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

const MIN_LENGTH_MESSAGE = (field: string, length: number): string =>
  `${field} must be at least ${length} characters long`;

export const triggerEventSchema = yup.object().shape({
  eventId: yup
    .string()
    .typeError(IS_STRING)
    .required(REQUIRED_MESSAGE("Event Type")),
  headcount: yup.number().min(1).typeError(IS_NUMBER).nullable(),
  emergencyType: yup.string().required(REQUIRED_MESSAGE("Event Type")),
});
export function IS_VALID(data: any) {
  try {
    triggerEventSchema.validate(data);
    return true;
  } catch (err) {
    return false;
  }
}

export interface AlertInProgressSchema {
  emergencyType: string;
  studentName: string;
  location: string;
  locationName: string;
  comments: string;
}
export interface AlertInProgressUpdateSchema {
  school?: string;
  acknowledgedComments?: string;
  alertId: string;
}

export const alertInProgressSchemaValidation = yup.object().shape({
  emergencyType: yup.string().required(REQUIRED_MESSAGE("Event Type")),
  location: yup.string(),
  studentName: yup
    .string()
    .typeError(IS_STRING)
    .required(REQUIRED_MESSAGE("Student Name")),
  locationName: yup.string().optional(),

  comments: yup.string().optional(),
});
