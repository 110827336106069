// --- UI DEPENDENCIES
import { Toolbar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
// --- DEPENDENCIES
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// --- COMPONENTS
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu } from "../../components/DataGrid";
import MainLayout from "../../components/MainLayout";
// --- REDUX
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
// --- SERVICE
import {
  changeActiveState,
  changeAlertsState,
  fetchUsersSafety,
} from "../../services/users";
import districtColumns from "./config/columns";
// --- CONST
import { IUsers } from "../../models/Users";
import { rolesFilter } from "./config/filterOptions";
import {
  roles,
  rolesNames,
  schoolRoles,
  schoolRolesNames,
} from "../../constants";

const UsersAlerts = () => {
  // --- HOOKS / STATES / SELECTORS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const history = useHistory();
  const [search, setSearch] = useState<string>("");
  const [localRole, setRole] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IUsers[]>([]);
  const validRoles = [
    ...Object.keys(schoolRolesNames).map((key) => schoolRolesNames[key]),
    "All Roles",
  ];
  const safetyCommanderRoles = rolesFilter.filter((role) =>
    validRoles.includes(role.name),
  );
  const validRolesToShow = [
    schoolRoles.SAFETY_COMMANDER,
    schoolRoles.SAFETY_TEAM,
    schoolRoles.TEACHER,
  ];

  useEffect(() => {
    initState();
  }, []);

  // --- MAIN METHODS

  async function initState(): Promise<void> {
    try {
      setIsLoading(true);
      const data = await fetchUsersSafety(user?.school?._id);
      const payload: any = data?.data?.payload;
      const filteredUsers = payload.filter((val: any) => {
        return (
          val.school._id === user.school._id &&
          validRolesToShow.includes(val.role.code)
        );
      });
      setUsers(filteredUsers);
      setIsLoading(false);
    } catch (error) {
      history.goBack();
    }
  }

  async function handleAlertsActivation(_id: string, alertsEnabled: boolean) {
    await changeAlertsState({ _id, alertsEnabled });
    const copyData = [...users];
    const objIndex = copyData.findIndex((user) => user._id === _id);
    copyData[objIndex].alertsEnabled = alertsEnabled;
    setUsers(copyData);
  }

  function handleOpenModal(isActivate: boolean, id: string) {
    const activationType = isActivate ? "DEACTIVATE" : "ACTIVATE";
    const modalConfig: PayloadOpenModal = {
      title: "Confirm",
      description: `Would you like to ${activationType} the alerts on this user?`,
      action: () => {
        handleAlertsActivation(id, !isActivate);
      },
    };
    dispatch(openModal(modalConfig));
  }

  function renderModalMenu(id: string, isActive: boolean) {
    const detailsOptionsList = [
      {
        name: isActive ? "DEACTIVATE alerts" : "ACTIVATE alerts",
        action: () => handleOpenModal(isActive, id),
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  }

  const actionsColumn: GridColDef = {
    headerName: "ACTIONS",
    field: "",
    hideSortIcons: true,
    renderCell: (data) => {
      return renderModalMenu(data.row._id, data.row.alertsEnabled);
    },
  };

  const handleFilter = () =>
    users
      ?.filter(
        (user) =>
          user.fullName.toLowerCase().includes(search.toLowerCase()) || !search,
      )
      .filter(
        (user) =>
          user?.role?.name?.includes(localRole) || localRole === "ALL ROLES",
      );

  // --- RENDER
  return (
    <>
      <Toolbar />
      <PageTitle title="Users" />
      <MainLayout>
        <DataGrid
          columns={districtColumns(actionsColumn)}
          rows={handleFilter()}
          checkboxSelection={false}
          getRowId={(row) => row._id}
          loading={isLoading}
          autoHeight
          toolbarProps={{
            filterOptions: safetyCommanderRoles,
            onSearchChange: (value) => setSearch(value.target.value),
            clearSearch: () => setSearch(""),
            onFilterChange(event) {
              const selectedRoleCode = Object.keys(rolesNames).filter(
                (key) => rolesNames[key] === event.target.value,
              )[0];
              const oldRoleName = Object.keys(roles).filter(
                (key) => roles[key] === selectedRoleCode,
              )[0];
              setRole((oldRoleName as string) || "");
            },
          }}
        />
      </MainLayout>
    </>
  );
};

export default UsersAlerts;
