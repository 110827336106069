import { Box, Button, Toolbar, Typography } from "@mui/material";
import React from "react";
import PageTitle from "../../../components/DashboardLayout/PageTitle";
import { TabPanel } from "../components/Tab";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import useModal from "../../../hooks/useModal";
import { StudentSafetyModal } from "../../UsersSafetyC/StudentSafetyAlertModal";

const WithOutEvent = () => {
  const handleAlertClick = () => {
    setOpenModal(true);
  };
  const { isOpen, setIsOpen: setOpenModal } = useModal();

  const closeModal = () => setOpenModal(false);

  return (
    <>
      <Box sx={{ height: `calc(100vh - ${64}px)`, marginTop: "10px" }}>
        <Toolbar />
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}
        >
          <Button
            sx={{
              color: "white",
              bgcolor: "red",
              "&:hover": {
                bgcolor: "red",
              },
            }}
            onClick={handleAlertClick}
            startIcon={<AddAlertIcon />}
          >
            Alerts
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
          }}
        >
          <Typography textAlign={"center"} variant="h4">
            No Events Running
          </Typography>
        </Box>
      </Box>
      <StudentSafetyModal open={isOpen} setClose={closeModal} />
    </>
  );
};

export default WithOutEvent;
