import { AxiosResponse, AxiosRequestConfig } from "axios";
import axios, { getToken } from "../config";
import { IAlertInProgress } from "../models/AlertsInProgress";
import { AlertInProgressUpdateSchema } from "../pages/UsersSafetyC/StudentSafetyAlertModal/schema";

export const addAlertInProgress = async (
  form: IAlertInProgress,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/alerts",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: form,
  };
  return axios(config);
};

export const fetchAlertsInProgress = (
  school: string,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/alerts?school=${school}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const getAlertInProgress = (data: any) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/alerts/${data.alertId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

export const updateAlertInProgress = async (
  form: AlertInProgressUpdateSchema,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/alerts/${form.alertId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: form,
  };
  return axios(config);
};
