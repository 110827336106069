import { Grid } from "@mui/material";
import { ClimbingBoxLoader } from "react-spinners";

const NoSessionRoutes = () => {
  return (
    <Grid
      style={{ height: "100vh" }}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <ClimbingBoxLoader loading={true} />
      <h4>This may take a moment, thanks for waiting.</h4>
    </Grid>
  );
};

export default NoSessionRoutes;
