import * as yup from "yup";
import { roles } from "../../../constants";

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be only numbers";
const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

/**
 * Yup Schema wich describe the data constrains of an specifc object
 */
export const updateUserValidationSchema = yup.object().shape({
  _id: yup.string().required(REQUIRED_MESSAGE("_id")),
  firstName: yup
    .string()
    .typeError(IS_STRING)
    .min(1)
    .required(REQUIRED_MESSAGE("firstName")),
  lastName: yup
    .string()
    .typeError(IS_STRING)
    .min(1)
    .required(REQUIRED_MESSAGE("lastName")),
  phoneNumber: yup
    .string()
    .typeError(IS_NUMBER)
    .matches(/^\d{10}$/, "Phone must contains 10 numbers")
    .required(REQUIRED_MESSAGE("phone")),
  role: yup
    .string()
    .typeError(IS_STRING)
    .oneOf(Object.keys(roles))
    .required(REQUIRED_MESSAGE("role")),
  school: yup.object().optional().nullable(),
  districts: yup.array().optional().nullable(),
});
