import React, { useEffect, useState } from "react";
// import { fetchAlertsData } from "../../services/alerts"; // Import the fetchAlertsData service
import { useSelector } from "../../redux/typedHooks";
import {
  GridColumnHeaderTitle,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  downloadAlertReport,
  downloadReport,
  fetchAlertsForReports,
} from "../../services/reports";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DataGrid, { ModalMenu, CustomSort } from "../../components/DataGrid";

import { Typography } from "@mui/material";
import fileDownload from "js-file-download";

interface IAlert {
  acknowledgedBy: string;
  acknowledgedComments: string;
  alertCreatedBy: string;
  alertStatus: string;
  alertUpdatedTime: string;
  closingTime: string;
  closingUserId: string;
  comments: string;
  creationTime: string;
  emergencyType: string;
  location: string;
  school: string;
  studentName: string;
  timeOfAcknowledgement: string;
  id: string;
}
export const AlertReport: React.FC = () => {
  const { user } = useSelector((state) => state.loggedUser.loggedUser);
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [emergencyType, setEmergencyType] = useState<any>();
  const history = useHistory();

  const handleFilter = () =>
    alerts?.filter(
      (alert) =>
        search === "" ||
        alert.studentName.toLowerCase().includes(search.toLowerCase()) ||
        !search,
    );

  const fetchAlerts = async () => {
    try {
      setLoading(true);
      const response = await fetchAlertsForReports(user.school._id);
      setAlerts([
        ...response.data.payload.alerts.map((alert: any) => ({
          ...alert,
          id: alert?._id || alert.id,
        })),
      ]);
    } catch (error) {
      console.error("Error fetching alerts data:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderModalMenu = (id: string, params: GridRenderCellParams) => {
    const name = `${moment(params.row.startDate).format(
      "MM/DD/YYYY, h:mm:ss a",
    )} - ${params.row.emergencyType.name}`;

    const detailsOptionsList = [
      {
        name: "Download Report",
        action: async () => {
          const { data } = await downloadAlertReport(id.toString());
          fileDownload(data, `${name}.xlsx`);
        },
      },
      {
        name: "View Alert In Progress",
        action: () => history.push(`/alerts-in-progress/${id}`),
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  };

  const columns: GridColDef[] = [
    {
      headerName: "Student Name",
      field: "studentName",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Location",
      field: "location",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      valueGetter: (params: any) => params.row.location.name,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Created By",
      field: "alertCreatedBy",
      flex: 1,
      hideSortIcons: true,
      valueGetter: (params: any) =>
        params.row.alertCreatedBy.firstName +
        " " +
        params.row.alertCreatedBy.lastName,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Event Type",
      field: "emergencyType",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const emergencyType = params.value.name;
        return (
          <div style={{ justifyContent: "center" }}>
            <span
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "6px 12px",
                borderRadius: "12px",
              }}
            >
              {emergencyType}
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "alertStatus",
      flex: 1,
      hideSortIcons: true,
      minWidth: 200,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description || "-"}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const { colDef } = params;
        return (
          <Typography fontSize="0.9rem" textAlign="center">
            {params.value ? params.value : "-"}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>{renderModalMenu(params.row._id, params)}</>;
      },
    },
  ];

  const allTypes = { value: "All Types", name: "All Types" };

  useEffect(() => {
    fetchAlerts();
  }, []);

  return (
    <div>
      <DataGrid
        columns={columns}
        loading={loading}
        rows={handleFilter()}
        toolbarProps={{
          hideFilters: true,
          onSearchChange: (value: any) => setSearch(value.target.value),
          clearSearch: () => setSearch(""),
          hideIcons: true,
        }}
      />
    </div>
  );
};
