import {
  Badge,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommetChat from "../../components/CommetChat";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import useModal from "../../hooks/useModal";
import { TabPanel } from "../EventInProgress/components/Tab";
import {
  getAlertInProgress,
  updateAlertInProgress,
} from "../../services/alertsInProgress";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AlertDetails from "./components/AlertDetails";
import { AlertStatus } from "../../constants";
import { StopAlertModal } from "./components/stopAlertModal";
import { userSelector } from "../../redux/selectors/user";
import { RL004 } from "../routes/routes";
import { displayToast } from "../../utils/helpers/displayToast";

export interface IAlertInProgress {
  studentName: string;
  location: {
    name: string;
  };
  emergencyType: {
    name: string;
  };
  creationTime: Date;
  alertStatus: string;
  alertUpdatedTime?: Date;
  alertCreatedBy: {
    firstName: string;
    lastName: string;
    _id: string;
  };
  updationTime?: Date;
  comments?: string;
  acknowledgedComments?: string;
  timeOfAcknowledgement?: Date;
  acknowledgedBy?: {
    firstName: string;
    lastName: string;
  };
  CloseTime?: Date;
  ClosingUserId?: string;
  school: {
    address: string;
    state: string;
    district: {
      name: string;
      zipCode: string;
    };
    name: string;
  };
}

const ViewAlertInProgress = () => {
  const { user } = useSelector((store: any) => store.loggedUser.loggedUser);
  const { isOpen, setIsOpen: setOpenModal } = useModal();

  const { isOpen: stopModalOpen, setIsOpen: setStopModalOpen } = useModal();
  const [report, setReport] = useState<IAlertInProgress | null>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [refetch, setRefetch] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const triggerRefetch = () => setRefetch(!refetch);
  const closeStopModal = () => setStopModalOpen(false);

  const [value, setValue] = React.useState(0);

  const fetchAlert = () => {
    setLoading(true);
    const data = {
      schoolId: user.school._id,
      alertId: id,
    };
    getAlertInProgress(data)
      .then(({ data }) => {
        setReport(data.payload);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAlert();
  }, [refetch]);

  const handleStopClicked = () => {
    setStopModalOpen(true);
  };

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }
  const acknowledgeHandler = async () => {
    try {
      setIsSubmitting(true);
      const form = {
        school: user.school._id,
        alertId: id,
        alertStatus: AlertStatus.Acknowledged,
      };

      await updateAlertInProgress(form);
      displayToast("success", "Acknowledgment notification sent");
      triggerRefetch();
    } catch (error) {
      displayToast("error", "Error sending acknowledgment");
      console.error("Error sending acknowledgment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle title="Alert Details" />
      <Box sx={{ height: `calc(100vh - ${64}px)`, marginTop: "70px" }}>
        <Toolbar />
        <Box sx={{ width: "100%" }}>
          {" "}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs value={value} onChange={handleChange}>
              <Tab label={<Typography sx={{ padding: 1 }}>Chat</Typography>} />

              <Tab
                label={
                  <Badge color="primary">
                    <Typography sx={{ padding: 1 }}>Alert Details</Typography>
                  </Badge>
                }
              />
            </Tabs>
            <Box
              sx={{
                justifyContent: { xs: "flex-end", sm: "center" },
                display: "flex",
                marginRight: "1rem",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-end", sm: "center" },
                marginTop: {
                  xs: ".4rem",
                  sm: 0,
                },
              }}
            >
              {report && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  
                      <Button
                        sx={{
                          color: "white",
                          bgcolor: "#4caf50",
                          marginRight: { lg: ".4rem", sm: 0 },
                          paddingInline: ".5rem",
                          "&:hover": {
                            bgcolor: "#6fbf73",
                          },
                          "&:disabled": {
                            bgcolor: "gray",
                          },
                        }}
                        onClick={() => acknowledgeHandler()}
                        startIcon={<CheckCircleOutlineIcon />}
                        disabled={report.alertStatus !== AlertStatus.InProgress}
                      >
                        Acknowledge
                        {loading || isSubmitting ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : null}
                      </Button>
                  <Box sx={{ margin: { xs: "0.5rem 0", sm: "0 .5rem" } }} />
                  <Button
                    sx={{
                      color: "white",
                      bgcolor: "#f44336",
                      paddingInline: ".5rem",
                      "&:hover": {
                        bgcolor: "#ff7961",
                      },
                      "&:disabled": {
                        bgcolor: "gray",
                      },
                    }}
                    onClick={() => {
                      handleStopClicked();
                    }}
                    startIcon={<HighlightOffIcon />}
                    disabled={report.alertStatus === AlertStatus.Stopped}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <>Stop</>
                    )}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <TabPanel value={value} index={0}>
            <CommetChat />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Paper sx={{ padding: 2, width: "100%" }}>
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              {report && <AlertDetails alert={report} />}
              <Grid
                sx={{
                  marginY: "12px",
                  width: "100%",
                  maxWidth: 500,
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              ></Grid>
            </Paper>
          </TabPanel>
        </Box>
        <StopAlertModal
          open={stopModalOpen}
          setClose={closeStopModal}
          refetch={triggerRefetch}
        />
      </Box>
    </>
  );
};

export default ViewAlertInProgress;
