import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { parse } from "query-string";
import { Redirect } from "react-router-dom";

import ConfirmEmailMessage from "../../components/ConfirmEmail";
import LoginLayout from "../../components/LoginLayout";

const ConfirmEmail: React.FC = () => {
  const { token, id } = parse(window.location.search);

  if (!token || !id) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginLayout showBanner={false}>
      <Card>
        <CardContent
          style={{
            paddingRight: 30,
            paddingLeft: 30,
            paddingTop: 0,
            height: "100%",
          }}
        >
          <ConfirmEmailMessage params={{ token, id }} />
        </CardContent>
      </Card>
    </LoginLayout>
  );
};

export default ConfirmEmail;
