interface IRoles {
  [key: string]: {
    name: string;
  };
}

export const ROLES: IRoles = {
  RL000: {
    name: "SUPERADMIN",
  },
  RL001: {
    name: "DISTRICT_ADMIN",
  },
  RL002: {
    name: "SAFETY_COMMANDER",
  },
  RL003: {
    name: "SAFETY_TEAM",
  },
  RL004: {
    name: "TEACHER",
  },
  RL005: {
    name: "ENROLLMENT_LEADER",
  },
};

export const RolesToNotify: IRoles = {
  RL000: {
    name: "Super Admin",
  },
  RL001: {
    name: "District Admin",
  },
  RL002: {
    name: "Safety Commander",
  },
  RL003: {
    name: "Safety Team",
  },
  RL004: {
    name: "Teacher",
  },
  RL005: {
    name: "Enrollment Leader",
  },
};

export enum ERoles {
  RL000 = "RL000",
  RL001 = "RL001",
  RL002 = "RL002",
  RL003 = "RL003",
  RL004 = "RL004",
  RL005 = "RL005",
}
export function getRoleName(role: ERoles): string {
  return RolesToNotify[role].name;
}
