import * as yup from "yup";

const IS_STRING = "This field must be a text value.";
const IS_NUMBER = "This field must be a number value.";
const REQUIRED_MESSAGE = (field: string): string =>
  `The field ${field} is required.`;

/**
 * Yup Schema wich describe the data constrains of an specifc object
 */
export const validationSchema = yup.object().shape({
  name: yup.string().typeError(IS_STRING).required(REQUIRED_MESSAGE("name")),
});
