// --- DEPENDENCIES
import { Button, Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
// --- COMPONENTS
// import UserFormSafetyC from "./UserFormSafetyC";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { LayoutContainer } from "../CreateSchool/CreateSchool";
// --- REDUX
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { useLoading } from "../../hooks/useLoading";
import EventTypeFormC from "./CreateEventTypeForm";
import { useFormik } from "formik";
import { validationSchema } from "./config/formValidation";
import {
  createEventType,
  getEventType,
  updateEventType,
} from "../../services/eventInProgress";
import { useEffect, useState } from "react";

export type IEventType = {
  name: string;
  description: string;
  drillEmergency: string;
  school?: any;
  protocolSteps: string;
};

/**CreateUserSafetyC component: Container that handles the logic of the create user form  */
const CreateEventTypeC = () => {
  // --- CONST / HOOKS
  const { handleClose, handleOpen } = useLoading();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<IEventType | null>(null);

  useEffect(() => {
    const getEventToEdit = async () => {
      try {
        const { data } = await getEventType({ id });
        setEvent({
          name: data.payload.name,
          description: data.payload.description,
          drillEmergency: data.payload.drillEmergency,
          protocolSteps: data.payload.protocolSteps,
        });
      } catch (error) {
        console.error("Error : ", error);
      }
    };

    getEventToEdit();
  }, []);

  useEffect(() => {
    formik.setValues({
      description: event?.description || "",
      name: event?.name || "",
      protocolSteps: event?.protocolSteps || "",
      drillEmergency: event?.drillEmergency || "",
    });
  }, [event]);

  const formik = useFormik({
    initialValues: {
      name: event?.name || "",
      description: event?.description || "",
      drillEmergency: event?.drillEmergency || "",
      protocolSteps: event?.protocolSteps || "",
    },
    validationSchema,
    onSubmit,
  });

  // --- MAIN METHODS
  async function onSubmit(values: IEventType): Promise<void> {
    const { description, name, protocolSteps, drillEmergency } = values;
    try {
      handleOpen();
      if (id) {
        await updateEventType({
          school: user.school._id,
          description,
          name,
          protocolSteps,
          drillEmergency,
          id,
        });
      } else {
        await createEventType({
          school: user.school._id,
          description,
          name,
          protocolSteps,
          drillEmergency,
        });
      }
      handleSuccessModal();
    } catch (err: any) {
      const mssg = err?.response?.data?.message;
      handleErrorModal(mssg);
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Success",
      description: `Event type ${
        id === undefined ? "created " : "updated "
      } successfully`,
      action: () => history.goBack(),
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal(errMsg?: string) {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: errMsg || `Error creating User`,
      hideSecondaryButton: true,
      primaryButtonText: "ACCEPT",
    };
    dispatch(openModal(modalConfig));
  }

  return (
    <>
      <PageTitle title="Create Event Type" />
      <MainLayout>
        <LayoutContainer>
          <EventTypeFormC formik={formik} />
          <Grid
            sx={{ paddingTop: "10px", paddingBottom: "10px" }}
            container
            spacing={3}
            justifyContent="flex-end"
          >
            <Button onClick={() => history.goBack()} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
              sx={{ marginLeft: "10px" }}
              variant="contained"
            >
              SAVE
            </Button>
          </Grid>
        </LayoutContainer>
      </MainLayout>
    </>
  );
};

export default CreateEventTypeC;
