import { ISchool } from "../../../models/School";

export const formatDistritValuesFilter = (data: ISchool[]) => {
  const districts = [{ value: "ALL DISTRICTS", name: "ALL DISTRICTS" }];
  const keys: string[] = [];
  data.forEach((element) => {
    const district = element?.district;
    if (district && keys.indexOf(district.name) === -1) {
      keys.push(district.name);
      districts.push({ name: district?.name, value: district?._id });
    }
  });
  return districts;
};
