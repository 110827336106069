import { roles } from "../../../constants";
import { IUpdateUserForm, IUserRequest, IUsers } from "../../../models/Users";

export function fitDataUpadateUser(formData: IUpdateUserForm): IUserRequest {
  const noDistricts = formData?.districts?.length === 0;
  const districts = noDistricts
    ? null
    : formData?.districts.map((element) => element._id);
  const fixedData: IUserRequest = {
    ...formData,
    role: roles[(formData as { role: "SAFETY_COMMANDER" })?.role],
    districts,
  };
  return fixedData;
}

export function fitReqUsertToForm(reqData: IUsers): IUpdateUserForm {
  const districts = reqData?.districts || [];
  const fixedData: IUpdateUserForm = {
    ...reqData,
    role: reqData.role.name,
    districts,
  };
  return fixedData;
}
