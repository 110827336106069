import { AxiosResponse, AxiosRequestConfig } from "axios";
import axios, { getToken } from "../config";
import { ITriggerEvent } from "../models/TriggerEvent";
import { IReportEditParam } from "../redux/actions/triggerEvent";
import { IEventType } from "../pages/CreateEventTypeC";

export const getAllEvents = async (): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/event_types",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
export const addEventInProgress = async (
  form: ITriggerEvent,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: form,
  };
  return axios(config);
};
export const getDrillCount = async (): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event/drill-count",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
export const getEmergencyCount = async (): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event/emergencies-count",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
/*    data: { ...form },
  };
  return axios(config);
}; */

export const stopEventInProgress = (school: string, headcount: number) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      school,
    },
    data: {
      headcount,
    },
  };
  return axios(config);
};

export const updateHeadCount = (headcount: number) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event/update-headcount",
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      headcount,
    },
  };
  return axios(config);
};

export const reportUpdateHeadCount = ({
  headcount,
  eventId,
  userId,
}: IReportEditParam) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event/update-finished-event",
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      headcount,
      eventId,
      userId,
    },
  };
  return axios(config);
};

export const getCurrentEvent = (school: string) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      school,
    },
  };
  return axios(config);
};
export const getLocationEvent = (data: any) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/current-event/location/user",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
  };

  return axios(config);
};

export const getEventLocationData = (locationId: string, school: string) => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/current-event/location`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      school,
    },
    params: {
      locationId,
    },
  };

  return axios(config);
};

export const createEventType = async (
  data: IEventType,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/event_types",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
  };
  return axios(config);
};

export const getEventType = async (data: {
  id: string;
}): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/event_types/${data.id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const updateEventType = async (data: any): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/event_types/${data.id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
    },
  };
  return axios(config);
};
