import {
  Box,
  Typography,
  PaginationItem,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { useGridApiContext, GridApiRef } from "@mui/x-data-grid";
import React from "react";

import styles from "./styles";

interface ICustomPagination {
  rowsPerPage?: number[];
}

/**
 * EL paginado del datagrid con un estilo completamente diferente
 * @param {number[]} rowsPerPage El arreglo que representa las columnas por página , pueden ser diferentes por página
 */

const CustomPagination: React.VFC<ICustomPagination> = (props) => {
  const { rowsPerPage } = props;
  const apiRef = useGridApiContext() as GridApiRef;
  const pagination = apiRef.current.state.pagination;
  const theme = useTheme();
  const smallScreen = window.screen.width < theme.breakpoints.values["sm"];

  const firstPageRows =
    pagination.page === 0 ? 1 : pagination.pageSize * pagination.page + 1;
  const lastPageRows =
    pagination.page === pagination.pageCount - 1
      ? pagination.rowCount
      : pagination.pageSize * (pagination.page + 1);

  const handleFirstPage = () => {
    apiRef.current.setPage(0);
  };

  const handlePreviousPage = () => {
    apiRef.current.setPage(pagination.page - 1);
  };

  const handleNextPage = () => {
    apiRef.current.setPage(pagination.page + 1);
  };

  const handleLastPage = () => {
    apiRef.current.setPage(pagination.pageCount - 1);
  };

  const handlePageSize = (event: SelectChangeEvent<unknown>) => {
    apiRef.current.setPageSize(parseInt(event.target.value as string));
  };

  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.text}>
        {smallScreen ? "Rows:" : "Rows per page:"}
      </Typography>
      {rowsPerPage && (
        <Select
          variant="standard"
          onChange={handlePageSize}
          value={pagination.pageSize}
          disableUnderline
          sx={styles.select}
        >
          {rowsPerPage.map((row, index) => (
            <MenuItem key={index} value={row.toString()}>
              {row}
            </MenuItem>
          ))}
        </Select>
      )}
      <Typography sx={styles.text}>
        {firstPageRows === lastPageRows
          ? lastPageRows
          : `${firstPageRows} - ${lastPageRows}`}{" "}
        of {pagination.rowCount}
      </Typography>
      <PaginationItem
        type="first"
        disabled={pagination.page === 0}
        onClick={handleFirstPage}
        sx={styles.firstPage}
      />
      <PaginationItem
        type="previous"
        disabled={pagination.page === 0}
        page={pagination.page}
        onClick={handlePreviousPage}
        sx={styles.pageButton}
      />
      <PaginationItem
        type="next"
        disabled={pagination.page === pagination.pageCount - 1}
        page={pagination.page}
        onClick={handleNextPage}
        sx={styles.pageButton}
      />
      <PaginationItem
        type="last"
        disabled={pagination.page === pagination.pageCount - 1}
        onClick={handleLastPage}
        sx={styles.lastPage}
      />
    </Box>
  );
};

export default CustomPagination;
