// ---Dependencies
import { Autocomplete, TextField } from "@mui/material";
import { ReactElement } from "react";
import { IDistrict } from "../../../../../models/District";
import { IUpdateUserFormProps } from "../../../../../models/Users";
import { ICatalogs } from "../../../config/useCatalogs";
// ---Types
// ---Components
// ---UI Dependencies
// ---Custom Hooks
// ---Redux
// ---Config
// ---Assets
// ---Utils
// ---Constants
// ---Services/Requests
// ---Images

interface Props extends IUpdateUserFormProps {
  localRole?: string | null;
  districts: ICatalogs["districts"];
}
/**
 * DistrictsInput Component: Autocomplete Input field that map the
 * districts in the options along their zip code and save the selected option
 * @param {Props} props - Formik object and list of districts
 * @returns {ReactElement}
 */
export function DistrictsInput({ formik, districts }: Props): ReactElement {
  // -----------------------MAIN METHODS
  function onChangeAutoComp(_: any, value: IDistrict[] | null) {
    formik.setFieldValue("districts", value);
  }
  const showError = formik.values.districts.length === 0;
  // -----------------------RENDER
  return (
    <Autocomplete
      multiple
      options={districts}
      value={formik.values.districts}
      onChange={onChangeAutoComp}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <h5 {...(props as any)}>
            {option?.name} (ZIP Code: {option?.zipCode})
          </h5>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Districts*"
          variant="outlined"
          error={showError}
          helperText={showError ? "Select at least 1 option" : ""}
        />
      )}
    />
  );
}
