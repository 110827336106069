import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import { modalStyles } from "../../TriggerEvent/TriggerEventModal/config/styles";
import React, { useEffect, useState } from "react";
import { SafetyAlertType } from "../../TriggerEvent/TriggerEventModal/constants/const";
import { useSelector } from "../../../redux/typedHooks";
import { fetchLocationsQuery } from "../../../services/locations";
import { TextInput } from "../../../components/Inputs";
import {
  AlertInProgressSchema,
  alertInProgressSchemaValidation,
} from "./schema";
import { useFormik } from "formik";
import { useStudentSafetyAlert } from "../../../hooks/useSafetyStudentSafetyAlert";
import { displayToast } from "../../../utils/helpers/displayToast";
import { getAllEvents } from "../../../services/eventInProgress";

// Define the props type for the UserSafetyListModal component
interface IStudentSafetyModalProps {
  open: boolean; // Indicates if the modal is open
  setClose: () => void; // Function to close the modal
}

interface Location {
  _id: string;
  name: string;
  // add other location properties as needed
}

export const StudentSafetyModal: React.FC<IStudentSafetyModalProps> = ({
  open,
  setClose,
}) => {
  const [locations, setLocations] = useState<Location[]>([]);

  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const [events, setEvents] = useState([]);
  useEffect((): void => {
    fetchevents();
  }, []);

  const fetchevents = async (): Promise<void> => {
    try {
      const response = await getAllEvents();
      if (response.data.success) {
        const eventsToSet = response.data.payload.map((event: any) => {
          return {
            value: event._id,
            name: event.name,
          };
        });
        setEvents(eventsToSet);
      }
      /*  */
    } catch (error) {
      displayToast("error", "An error has happened fetching the events");
    } finally {
    }
  };

  const { handleSubmit } = useStudentSafetyAlert({
    onSuccess: () => {
      setClose();
      formik.resetForm();
    },
  });

  const formik = useFormik<AlertInProgressSchema>({
    initialValues: {
      comments: "",
      location: "",
      studentName: "",
      locationName: "",
      emergencyType: "",
    },
    validationSchema: alertInProgressSchemaValidation,
    onSubmit: (values) => handleSubmit(values, user._id, user.school._id),
  });

  useEffect(() => {
    fetchLocationsQuery(`?school=${user.school._id}&active=true`).then(
      ({ data }: any) => {
        setLocations(data);
      },
    );
  }, [user.school._id]);

  return (
    <Dialog open={open} onClose={setClose} aria-labelledby="dialog-modal">
      <DialogTitle sx={modalStyles.dialogTitle}>
        Notify team about the student safety
      </DialogTitle>
      <DialogContent style={{ paddingTop: 20 }}>
        <Box
          sx={{ width: { sm: "100%", lg: 500 }, margin: "0 auto", padding: 2 }}
        >
          <TextField
            select
            label="Event Type"
            value={formik.values.emergencyType || ""}
            onChange={formik.handleChange("emergencyType")}
            onBlur={formik.handleBlur("emergencyType")}
            error={!!formik.errors.emergencyType}
            helperText={formik.errors.emergencyType}
            fullWidth
            sx={{ marginBottom: 2 }}
          >
            {events.map(({ name, value }, index) => (
              <MenuItem key={index} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Student Name"
            value={formik.values.studentName}
            onChange={formik.handleChange("studentName")}
            fullWidth
            sx={{ marginBottom: 2 }}
            helperText={formik.errors.studentName}
            error={!!formik.errors.studentName}
          />
          <Autocomplete
            freeSolo
            fullWidth
            id="location-autocomplete"
            options={locations}
            getOptionLabel={(option) => {
              if (typeof option === "string") return option;
              return option?.name || "";
            }}
            value={
              locations.find((loc) => loc._id === formik.values.location) ||
              formik.values.locationName
            }
            onChange={(_, newValue) => {
              if (typeof newValue === "string") {
                formik.setFieldValue("location", "");
                formik.setFieldValue("locationName", newValue);
              } else if (newValue && "_id" in newValue) {
                formik.setFieldValue("location", newValue._id);
                formik.setFieldValue("locationName", "");
              } else {
                formik.setFieldValue("location", "");
                formik.setFieldValue("locationName", "");
              }
            }}
            onInputChange={(_, newInputValue) => {
              if (!formik.values.location) {
                formik.setFieldValue("locationName", newInputValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Location*"
                error={
                  !!formik.touched.location &&
                  !formik.values.location &&
                  !formik.values.locationName
                }
                helperText={
                  formik.touched.location &&
                  !formik.values.location &&
                  !formik.values.locationName
                    ? "Please select or enter a location"
                    : ""
                }
                sx={{ marginBottom: 2 }}
              />
            )}
          />

          <Grid mt={2} item xs={12} style={{ marginBottom: "60px" }}>
            <TextInput
              label="Comments"
              value={formik.values.comments}
              onBlur={formik.handleBlur("comments")}
              onChange={formik.handleChange("comments")}
              multiline
              rows={4}
              error={!!formik.errors.comments}
              helperText={formik.errors.comments}
              fullWidth
            />
          </Grid>
        </Box>
        <DialogActions sx={modalStyles.dialogButtonsWrapper}>
          <Button
            color="primary"
            onClick={() => {
              setClose();
              formik.resetForm();
            }}
            disabled={false}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={formik.isSubmitting}
            color="primary"
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            Submit
            {formik.isSubmitting && (
              <CircularProgress
                style={{
                  position: "absolute",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                size={24}
              />
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
