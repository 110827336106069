import { schoolRolesNames } from "../../../constants";
import { IDistrict } from "../../../models/District";
import { ISchool } from "../../../models/School";

export const rolesFilter: {
  name: string;
  value: string;
}[] = [
  {
    name: "All Roles",
    value: "All Roles",
  },
  ...Object.keys(schoolRolesNames).map((key: string) => ({
    name: schoolRolesNames[key],
    value: schoolRolesNames[key],
  })),
];

export function parseSchools(data: ISchool[], districts?: IDistrict[]) {
  let schools: ISchool[] = [];
  districts?.forEach((userDis: any) => {
    const filtredSchools = [...data].filter(
      (school) => school?.district?._id === userDis?._id,
    );

    schools = [...schools, ...filtredSchools];
  });
  return schools;
}
