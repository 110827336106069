import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  textContainer: {
    background: "#f9f9f9",
    fontWeight: 400,
    text: "black",
    padding: "12px",
    display: "flex",
  },
});
