import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSocket } from "../../../hooks/useSocket";
import {
  changeHeadcount,
  setDataEventInProgress,
} from "../../../redux/actions/triggerEvent";
import { useDispatch, useSelector } from "../../../redux/typedHooks";
import { getCurrentEvent } from "../../../services/eventInProgress";
import { ItemGrid } from "./CustomCard";
import EditHeadCountDialog from "./EditHeadCountDialog";

const HeadCountInfo = () => {
  const { triggerEvent } = useSelector((store) => store);
  const { data } = useSelector((store) => store.triggerEvent);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { socket } = useSocket();
  const schoolId = user.school._id;
  const userRoleCode = user.role.code;

  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const actualHeadCount = data.locations.reduce(
    (acc: number, next: any) => (acc += next.headCount ?? 0),
    0,
  );
  const additionalHeadCount = data.locations.reduce(
    (acc: number, next: any) => (acc += next.additionalCount ?? 0),
    0,
  );
  const [isLoading, setIsLoading] = useState(true);

  const activeLocations = data.locations.length;
  const handleCloseModal = () => setShowConfirmation(false);

  const handleUpdateHeadcount = (newHeadcount: number) => {
    return () => {
      socket?.emit("update-headcount", { schoolId, role: userRoleCode });
      dispatch(changeHeadcount(newHeadcount));
      handleCloseModal();
    };
  };

  useEffect(() => {
    setIsLoading(true);
    getCurrentEvent(user.school._id)
      .then(({ data }) => {
        dispatch(setDataEventInProgress(data.payload));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [data.expectedHeadCount]);

  return (
    <ItemGrid>
      <Typography variant="h6" align="left" gutterBottom>
        Head Count Info
      </Typography>
      <Box
        sx={{
          paddingTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Box>
          <Typography align="left">Expected Head Count</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            {isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Typography
                variant="subtitle1"
                sx={{ color: "red" }}
                align="left"
              >
                {data.expectedHeadCount || "To be confirmed"}
              </Typography>
            )}
            <Button
              sx={{ cursor: "pointer" }}
              onClick={() => setShowConfirmation(true)}
            >
              <ModeEditIcon fontSize="small" />
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography align="left">Actual Head Count</Typography>
          <Typography variant="subtitle1" align="left">
            {actualHeadCount}
          </Typography>
        </Box>
        <Box>
          <Typography align="left">Active Location</Typography>
          <Typography variant="subtitle1" align="left">
            {activeLocations}
          </Typography>
        </Box>
        <Box>
          <Typography align="left">Additional Count</Typography>
          <Typography variant="subtitle1" sx={{ color: "red" }} align="left">
            {additionalHeadCount}
          </Typography>
        </Box>
      </Box>
      {showConfirmation && (
        <EditHeadCountDialog
          open={true}
          title={`Would you like to EDIT the headcount?`}
          cancelButtonAction={handleCloseModal}
          confirmButtonAction={handleUpdateHeadcount}
          originalHeadCount={triggerEvent.data.expectedHeadCount}
        />
      )}
    </ItemGrid>
  );
};

export default HeadCountInfo;
