import {
  TypedUseSelectorHook,
  useDispatch as NonTypedUseDispatch,
  useSelector as NonTypedUseSelector,
} from "react-redux";

import type { RootState, AppDispatch } from "../store";

export const useDispatch = () => NonTypedUseDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = NonTypedUseSelector;
