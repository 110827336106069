import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box
          sx={{
            padding: 2,
            height: {
              xs: "calc(100vh - 270px);",
              md: "70vh",
            },
            width: "100%",
            marginTop: "24px",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
