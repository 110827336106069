import {
  Badge,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import DataGrid, { ModalMenu, CustomSort } from "../../components/DataGrid";
import { displayToast } from "../../utils/helpers/displayToast";
import { AlertStatus, EVENT_EMERGENCY_TYPES } from "../../constants";
import { ISelectOption } from "../../components/Inputs/Select";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import {
  downloadReport,
  fetchAlertsForReports,
  fetchEventsForReports,
} from "../../services/reports";
import moment from "moment";
import fileDownload from "js-file-download";
import EditHeadCountDialog from "../DashboardSafetyCommander/components/EditHeadCountDialog";
import {
  IReportEditParam,
  reportChangeHeadcount,
} from "../../redux/actions/triggerEvent";
import { TabPanel } from "../EventInProgress/components/Tab";
import { AlertReport } from "../../components/AlertReports";

interface IEvent {
  id: string;
  startDate: string;
  startTime: string;
  school: string;
  eventId: string;
  elapsedTime: string;
  headcount: number;
  emergencyType: string;
  eventType: string;
  endDate: string;
  status: string;
}

const Reports: React.FC = () => {
  const { user } = useSelector((state) => state?.loggedUser?.loggedUser);
  const [types, setTypes] = useState<any>();
  const [emergencyType, setEmergencyType] = useState<any>();
  const [search, setSearch] = useState<string>("");
  const allTypes = { value: "All Types", name: "All Types" };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [headCount, setHeadCount] = useState<number>(0);
  const [updateHeadcount, setUpdateHeadcount] = useState<IReportEditParam>({
    headcount: 0,
    eventId: "",
    userId: "",
  });
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  const handleCloseModal = () => setShowConfirmation(false);

  const [reports, setReports] = useState<IEvent[]>([]);

  const handleUpdateHeadcount = (newHeadcount: number) => {
    return async () => {
      await reportChangeHeadcount({
        ...updateHeadcount,
        headcount: newHeadcount,
      })(dispatch);
      fetchData();
      displayToast("success", "Head count has been successfully updated");
      handleCloseModal();
    };
  };

  const renderModalMenu = (params: GridRenderCellParams) => {
    const name = `${moment(params.row.startDate).format(
      "MM/DD/YYYY, h:mm:ss a",
    )} - ${params.row.eventType}`;

    const detailsOptionsList = [
      {
        name: "Download Report",
        action: async () => {
          const { data } = await downloadReport(params.id.toString());
          fileDownload(data, `${name}.xlsx`);
        },
      },
      {
        name: "Change Headcount",
        action: async () => {
          setShowConfirmation(true);
          setHeadCount(params?.row?.headcount);
          setUpdateHeadcount({
            ...updateHeadcount,
            eventId: params?.row?.id,
            userId: user?._id,
          });
        },
      },
    ];

    return <ModalMenu items={detailsOptionsList} />;
  };

  async function fetchData() {
    try {
      const response = await fetchEventsForReports(user.school._id);
      setReports([
        ...response.data.payload.events.map((event: any) => ({
          ...event,
          id: event?._id || event.id,
          startTime: event.startDate,
          headcount: event.headcount,
          elapsedTime: moment
            .utc(
              moment
                .duration(moment(event.endDate).diff(event.startDate))
                .asMilliseconds(),
            )
            .format("HH:mm:ss"),
        })),
      ]);
    } catch (error) {
      displayToast("error", "Error obtaining reports");
    } finally {
      setLoading(false);
    }
  }

  function setLocationTypes() {
    const emergencyTypes: ISelectOption[] = EVENT_EMERGENCY_TYPES.map(
      (value) => {
        return {
          value: value.charAt(0).toUpperCase() + value.slice(1),
          name: value.charAt(0).toUpperCase() + value.slice(1),
        };
      },
    );
    setTypes([allTypes, ...emergencyTypes]);
  }

  useEffect(() => {
    setLocationTypes();
    setEmergencyType("All Types");
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      headerName: "Start Date",
      field: "startDate",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return moment(new Date(params.value)).format("MMMM Do YYYY");
      },
    },
    {
      headerName: "Start Time",
      field: "startTime",
      flex: 1,
      hideSortIcons: false,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return moment(new Date(params.value)).format("h:mm:ss a");
      },
    },
    {
      headerName: "Event Type",
      field: "eventType",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Emergency/Drill",
      field: "emergencyType",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography fontSize="0.9rem">
            {params.value.charAt(0).toUpperCase() + params.value.slice(1)}
          </Typography>
        );
      },
    },
    {
      headerName: "Elapsed Time",
      field: "elapsedTime",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return <Typography fontSize="0.9rem">{params.value}</Typography>;
      },
    },
    {
      headerName: "Headcount",
      field: "headcount",
      flex: 1,
      hideSortIcons: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return <Typography fontSize="0.9rem">{params.value}</Typography>;
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      hideSortIcons: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>{renderModalMenu(params)}</>;
      },
    },
  ];

  const handleFilter = () =>
    reports
      ?.filter(
        (report) =>
          search === "" ||
          report.eventType.toLowerCase().includes(search.toLowerCase()) ||
          !search,
      )
      .filter(
        (report) =>
          report?.emergencyType
            ?.toLowerCase()
            .includes(emergencyType.toLowerCase()) ||
          emergencyType === "All Types",
      );

  return (
    <>
      <PageTitle title="Reports" />
      <div
        style={{ marginTop: "62px", marginLeft: "32px", marginRight: "32px" }}
      >
        <Box sx={{ height: `calc(100vh - ${64}px)` }}>
          <Toolbar />
          <Box sx={{ width: "100%" }}>
            {" "}
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  label={<Typography sx={{ padding: 1 }}>Events</Typography>}
                />

                <Tab
                  label={
                    <Badge color="primary">
                      <Typography sx={{ padding: 1 }}>Alerts</Typography>
                    </Badge>
                  }
                />
              </Tabs>
            </Box>
            <TabPanel index={0} value={value}>
              <DataGrid
                columns={columns}
                rows={handleFilter()}
                loading={loading}
                toolbarProps={{
                  filterOptions: types,
                  onSearchChange: (value) => setSearch(value.target.value),
                  clearSearch: () => setSearch(""),
                  onFilterChange(event) {
                    setEmergencyType(event.target.value as string);
                  },
                  hideIcons: true,
                }}
              />
              {showConfirmation && (
                <EditHeadCountDialog
                  open={true}
                  title={`Would you like to EDIT the headcount?`}
                  cancelButtonAction={handleCloseModal}
                  confirmButtonAction={handleUpdateHeadcount}
                  originalHeadCount={headCount}
                />
              )}
            </TabPanel>
            <TabPanel index={1} value={value}>
              <AlertReport />
            </TabPanel>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Reports;
