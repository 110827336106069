// ---Dependencies
import React, { useEffect, useState } from "react";
// ---UI Dependencies
import { Autocomplete, Box, MenuItem, TextField } from "@mui/material";
// ---Config
import {
  DISTRICT_OPTIONS,
  GRADE_LEVELS_OPTIONS,
  STATES_USA,
} from "../../constants";
// ---Redux
import { useSelector } from "../../redux/typedHooks";
// ---Types
import { ISchoolFormProps } from "./ISchoolFormProps";
import { AnyARecord } from "dns";
import { IDistrict } from "../../models/District";
// -----------------------STYLES
const styles = {
  box: {
    maxWidth: "500px",
    marginTop: 8,
    "& .MuiTextField-root": { m: 1, width: "500px" },
    flex: 1,
  },
};
interface AutoCompleteOption {
  name: string;
  value: string;
}

/**
 * SchoolForm Component: Component that contain the form input fields
 * to register a new school
 * @param {ISchoolFormProps} props
 * @returns {React.ReactElement} React.ReactElement
 */
const SchoolForm: React.FC<ISchoolFormProps> = ({ formik }) => {
  // -----------------------CONSTS, HOOKS, STATES
  const [DistrictValue, setDistrictValue] = useState<AutoCompleteOption>({
    name: "",
    value: "",
  });
  const { districts } = useSelector(
    (store) => store.loggedUser.loggedUser.user,
  ) as { districts: IDistrict[] };

  useEffect(() => {
    const valueDistrict = districts.find(
      (district) => district._id === formik.values.district,
    );
    setDistrictValue(() => ({
      name: valueDistrict?.name || "",
      value: valueDistrict?._id || "",
    }));
  }, [formik.values.district]);

  function optionsAutoComplete(districts: IDistrict[]) {
    return districts.map((i) => ({ name: i.name, value: i._id }));
  }

  // -----------------------RENDER
  return (
    <Box sx={styles.box}>
      <TextField
        label="Name*"
        variant="outlined"
        value={formik.values.name}
        error={!!formik.errors.name && !!formik.touched.name}
        helperText={
          !!formik.errors.name && !!formik.touched.name && formik.errors.name
        }
        onChange={formik.handleChange("name")}
      />
      <TextField
        label="Address*"
        variant="outlined"
        value={formik.values.address}
        error={!!formik.errors.address && !!formik.touched.address}
        helperText={
          !!formik.errors.address &&
          !!formik.touched.address &&
          formik.errors.address
        }
        onChange={formik.handleChange("address")}
      />
      <Autocomplete
        options={optionsAutoComplete(districts)}
        value={DistrictValue}
        isOptionEqualToValue={(option, value) => {
          return String(option.value) === String(value.value);
        }}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option: any) => {
          return (
            <li {...props} key={option._id}>
              {option.name}
            </li>
          );
        }}
        onChange={(_, value) =>
          formik.setFieldValue("district", value?.value || "")
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="District*"
            variant="outlined"
            error={!!formik.errors.district && !!formik.touched.district}
            helperText={
              !!formik.errors.district &&
              !!formik.touched.district &&
              formik.errors.district
            }
          />
        )}
      />
      <Autocomplete
        value={formik.values.state}
        onChange={(e, value) => formik.setFieldValue("state", value)}
        options={STATES_USA}
        renderInput={(params) => (
          <TextField
            {...params}
            label="State*"
            variant="outlined"
            error={!!formik.errors.state && !!formik.touched.state}
            helperText={
              !!formik.errors.state &&
              !!formik.touched.state &&
              formik.errors.state
            }
          />
        )}
      />
      <TextField
        label="Zip Code*"
        variant="outlined"
        value={formik.values.zipCode}
        error={!!formik.errors.zipCode && !!formik.touched.zipCode}
        helperText={
          !!formik.errors.zipCode &&
          !!formik.touched.zipCode &&
          formik.errors.zipCode
        }
        onChange={formik.handleChange("zipCode")}
      />
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!formik.errors.gradeLevels && !!formik.touched.gradeLevels}
            helperText={
              !!formik.errors.gradeLevels &&
              !!formik.touched.gradeLevels &&
              formik.errors.gradeLevels
            }
            label="Grade Levels*"
          />
        )}
        multiple
        value={formik.values.gradeLevels}
        onChange={(e, value) => formik.setFieldValue("gradeLevels", value)}
        options={GRADE_LEVELS_OPTIONS.map((value, index) => value)}
      />
      <TextField
        label="Total Student Enrollment*"
        variant="outlined"
        value={formik.values.totalStudentsEnrollment}
        error={
          !!formik.errors.totalStudentsEnrollment &&
          !!formik.touched.totalStudentsEnrollment
        }
        helperText={
          !!formik.errors.totalStudentsEnrollment &&
          !!formik.touched.totalStudentsEnrollment &&
          formik.errors.totalStudentsEnrollment
        }
        onChange={formik.handleChange("totalStudentsEnrollment")}
      />
    </Box>
  );
};

export default SchoolForm;
